/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import {Button, Form, FormControl, Table} from "react-bootstrap";
import {useEffect, useState} from "react";
import Layout from "../Layout";
import {SERVER_URL} from "../Constants";
import ReactFlagsSelect from "react-flags-select";
import * as ToastHelper from "../ToastHelper";
import {IconContext} from "react-icons";
import {MdOutlineLogin, MdOutlineLogout} from "react-icons/md";
import {FiSettings} from "react-icons/fi";
import {toast, ToastContainer} from "react-toastify";
import {FaSpinner} from "react-icons/fa";


function AccountCreation() {


    const [doTutorial, setDoTutorial] = useState(false);
    const [useExistingLobby, setUseExistingLobby] = useState(false);
    const [existingLobbyEmail, setExistingLobbyEmail] = useState('');
    const [existingLobbyPassword, setExistingLobbyPassword] = useState('');
    const [numberOfAccounts, setNumberOfAccounts] = useState(1);
    const [serverData, setServerData] = useState([])
    const [selectedServer, setSelectedServer] = useState('');
    const [createdAccounts, setCreatedAccounts] = useState([]);

    const languageToCountryCode = {
        en: "GB", // Map "en" to "GB" for the United Kingdom
    };

    const countryCodeToLanguage = {
        GB: "en", // Map "en" to "GB" for the United Kingdom
    };

    const countries = [...new Set(serverData.map(server => languageToCountryCode[server.language] || server.language.toUpperCase()))];
    const [selectedCountry, setSelectedCountry] = useState('');
    const [filteredServers, setFilteredServers] = useState([]);

    const handleCountryChange = (event) => {
        const country = event
        setSelectedCountry(country);
        const parsedCountry = countryCodeToLanguage[country] || country.toLowerCase()
        setFilteredServers(serverData.filter(server => server.language === parsedCountry));
        setSelectedServer(''); // Reset the selected server
    };

    const handleServerChange = (event) => {
        setSelectedServer(event.target.value);
    };

    async function getCreatedAccounts() {
        const res = await fetch(SERVER_URL + "/api/account/getcreatedaccounts", {
            method: 'GET',
            credentials: 'include',
        });
        const json = await res.json();
        if (json.error) {
            return;
        }
        setCreatedAccounts(json);
    }

    async function getIkariamServers() {
        const res = await fetch(SERVER_URL + "/api/account/getIkariamServers", {
            method: 'GET',
            credentials: 'include',
        });
        const json = await res.json();
        if (json.error) {
            return;
        }
        setServerData(json);
    }

    async function createAccounts() {

        if (useExistingLobby) {
            if (existingLobbyEmail.trim().length === 0) {
                ToastHelper.notifyFailed("Email can't be empty");
                return;
            }

            if (existingLobbyPassword.trim().length === 0) {
                ToastHelper.notifyFailed("Password can't be empty");
                return;
            }
        }

        const jsonMission = {
            useExistingLobby: useExistingLobby,
            existingLobbyEmail: existingLobbyEmail,
            existingLobbyPassword: existingLobbyPassword,
            accountNumber: numberOfAccounts,
            doTutorial: doTutorial,
            serverInfos: selectedServer,
        }

        const loadingToast = toast.info('Creating accounts ...', {
            icon: <FaSpinner className="spinner"/>, // Custom spinner icon
            hideProgressBar: true,
            autoClose: 180000,
            pauseOnHover: false,
            position: "top-right",
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });

        const res = await fetch(SERVER_URL + "/api/account/accountCreation", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonMission)
        });
        const jsonRes = await res.json();
        if (jsonRes.error) {
            toast.update(loadingToast, {
                render: "An error has occurred: " + jsonRes.error,
                type: toast.TYPE.ERROR,
                autoClose: 5000,
                hideProgressBar: false,
                theme: "dark",
            });

        } else {

            toast.update(loadingToast, {
                render: "Success, " + jsonRes.amount + " account(s) have been created",
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                theme: "dark",
            });

        }
        console.log(jsonRes)
    }

    useEffect(() => {
        getIkariamServers()
        getCreatedAccounts()

        const getDataInterval = setInterval(() => {
            getCreatedAccounts()
        }, 15000);

    }, []);


    return (
        <div>
            <Layout>
                <ToastContainer/>
                <p>AccountCreation</p>

                <form>
                    <div className="mb-4">
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                checked={doTutorial}
                                onChange={() => setDoTutorial(!doTutorial)}
                                className="mr-2"
                            />
                            Log in City growth (includes tutorial)
                        </label>
                    </div>
                    <div className="mb-4">
                        <label className="flex items-center">
                            <input
                                type="radio"
                                checked={!useExistingLobby}
                                onChange={() => setUseExistingLobby(false)}
                                className="mr-2"
                            />
                            Create new lobby
                        </label>
                        <label className="flex items-center mt-2">
                            <input
                                type="radio"
                                checked={useExistingLobby}
                                onChange={() => setUseExistingLobby(true)}
                                className="mr-2"
                            />
                            Use existing lobby
                        </label>
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1">Existing Lobby Email</label>
                        <input
                            type="text"
                            value={existingLobbyEmail}
                            onChange={(e) => setExistingLobbyEmail(e.target.value)}
                            disabled={!useExistingLobby}
                            className="w-full p-2 border rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1">Existing Lobby Password</label>
                        <input
                            type="password"
                            value={existingLobbyPassword}
                            onChange={(e) => setExistingLobbyPassword(e.target.value)}
                            disabled={!useExistingLobby}
                            className="w-full p-2 border rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1">Number of Accounts</label>
                        <input
                            type="number"
                            value={numberOfAccounts}
                            onChange={(e) => setNumberOfAccounts(e.target.value)}
                            className="w-full p-2 border rounded"
                        />
                    </div>
                    <ReactFlagsSelect
                        className={"mt-4"}
                        searchable={true}
                        searchPlaceholder="Search countries"
                        placeholder="Select Server"
                        selected={selectedCountry}
                        countries={countries}
                        onSelect={handleCountryChange}/>

                    <select value={selectedServer} onChange={handleServerChange}
                            className="block w-full border border-gray-400 rounded-lg p-2" required>
                        <option value="">Select a server</option>
                        {filteredServers.map(server => (
                            <option key={server.accountGroup} value={server.accountGroup}>{server.name}</option>
                        ))}
                    </select>
                    <button type="button" onClick={createAccounts} className="bg-blue-500 text-white px-4 py-2 rounded">
                        Submit
                    </button>
                </form>
                <div>
                    <table className=" min-w-max w-full table-auto rounded-t">
                        <thead>
                        <tr className="bg-custom-orange text-gray-600 uppercase text-sm leading-normal">
                            <th className="py-3 text-center">Email</th>
                            <th className="py-3 text-center">Account name</th>
                            <th className="py-3 text-center">Password</th>
                            <th className="py-3 text-center">Server infos</th>
                            <th className="py-3 text-center">State</th>

                        </tr>
                        </thead>
                        <tbody>
                        {createdAccounts.map((createdAccount, i) => {
                            return <tr>
                                <td className="py-3 px-6 text-center whitespace-nowrap">{createdAccount.email}</td>
                                <td className="py-3 px-6 text-center whitespace-nowrap">{createdAccount.accountName}</td>
                                <td className="py-3 px-6 text-center whitespace-nowrap">{createdAccount.password}</td>
                                <td className="py-3 px-6 text-center whitespace-nowrap">{createdAccount.serverInfos}</td>
                                <td className="py-3 px-6 text-center whitespace-nowrap">{createdAccount.state}</td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                </div>
            </Layout>
        </div>
    );

}

export default AccountCreation;