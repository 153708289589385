/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import {useEffect, useState} from "react";
import {Form, InputGroup, Modal} from "react-bootstrap";
import {JSON_BUILDINGS, SERVER_URL} from "../../Constants";
import transporter from './../../assets/cities/transporters.PNG'
import freighter from './../../assets/cities/freighters.PNG'
import goldIcon from './../../assets/cities/icon_gold.PNG'
import populationIcon from './../../assets/cities/icon_population.png'
import medalIcon from './../../assets/cities/medal.png'
import islandIcon from './../../assets/cities/island.PNG'
import favourIcon from './../../assets/cities/favour.png'
import scientistIcon from './../../assets/cities/icon_scientist.png'
import researchPointIcon from './../../assets/cities/icon_research.png'
import researchPointPerHourIcon from './../../assets/cities/icon_research_time.png'

import woodIcon from './../../assets/cities/icon_wood.png'
import wineIcon from './../../assets/cities/icon_wine.png'
import marbleIcon from './../../assets/cities/icon_marble.png'
import crystalIcon from './../../assets/cities/icon_glass.png'
import sulfurIcon from './../../assets/cities/icon_sulfur.png'

import poseidonIcon from './../../assets/cities/poseidon.png'
import hephaestusIcon from './../../assets/cities/hephaestus.png'
import hadesIcon from './../../assets/cities/hades.png'
import aresIcon from './../../assets/cities/ares.png'
import demeterIcon from './../../assets/cities/demeter.png'
import athenaIcon from './../../assets/cities/athena.png'
import hermesIcon from './../../assets/cities/hermes.png'
import colossusIcon from './../../assets/cities/colossus.png'

import '../../App.css'

function CityGrowthInformation(props) {

    const [cityGrowthInfo, setCityGrowthInfo] = useState([])
    const [showBuildings, setShowBuildings] = useState(true)
    const [selectedCity, setSelectedCity] = useState();
    const [selectedCityData, setSelectedCityData] = useState();

    function getResourceIconById(id) {
        switch (id) {
            case 0:
                return woodIcon;
            case 1:
                return wineIcon;
            case 2:
                return marbleIcon;
            case 3:
                return crystalIcon;
            case 4:
                return sulfurIcon;
            default:
                return null; // or a default icon if you have one
        }
    }

// Function to get god icon by ID
   function getGodIconById(id) {
        switch (id) {
            case 1:
                return hephaestusIcon;
            case 2:
                return hadesIcon;
            case 3:
                return demeterIcon;
            case 4:
                return athenaIcon;
            case 5:
                return hermesIcon;
            case 6:
                return aresIcon;
            case 7:
                return poseidonIcon;
            case 8:
                return colossusIcon;
            default:
                return null; // or a default icon if you have one
        }
    }


    const GodEnum = {
        PAN: 1,
        DIONYSUS: 2,
        TYCHE: 3,
        PLUTUS: 4,
        THEIA: 5,
        HEPHAESTUS: 6
    };

    const godMapping = {
        1: "PAN",
        2: "DIONYSUS",
        3: "TYCHE",
        4: "PLUTUS",
        5: "THEIA",
        6: "HEPHAESTUS"
    };


    function isJsonObject(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    const chunkArray = (array, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    };

    const chunkedBuildings = chunkArray(JSON_BUILDINGS, 10);

    const handleCityChange = (event) => {
        setSelectedCity(Number(event.target.value));
        setSelectedCityData(props.cityGrowth.accountInformation.cityArrayInformation.find(city => city.id === Number(event.target.value)))
    };

    function formatNumberWithCommas(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    useEffect(() => {
        if (props.cityGrowth) {
            setCityGrowthInfo(props.cityGrowth.accountInformation.cityArrayInformation)
            setSelectedCity(props.cityGrowth.accountInformation.cityArrayInformation[0]?.id)
            setSelectedCityData(props.cityGrowth.accountInformation.cityArrayInformation.find(city => city.id === props.cityGrowth.accountInformation.cityArrayInformation[0]?.id))
        }
    }, [props.cityGrowth])

    return (
        <div>
            <Modal
                size="xl"
                show={props.showModal}
                onHide={props.closeModal}
            >
                <ul className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row" role="tablist">
                    <li className="-mb-px mr-2 last:mr-0 flex-auto text-center hover:cursor-pointer">
                        <a
                            className={
                                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                                (showBuildings === true
                                    ? "text-white bg-custom-orange"
                                    : "text-custom-orange bg-white")
                            }
                            onClick={e => {
                                e.preventDefault();
                                setShowBuildings(true);
                            }}
                        >
                            Buildings
                        </a>
                    </li>
                    <li className="-mb-px mr-2 last:mr-0 flex-auto text-center hover:cursor-pointer">
                        <a
                            className={
                                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                                (showBuildings === false
                                    ? "text-white bg-custom-orange"
                                    : "text-custom-orange bg-white")
                            }
                            onClick={e => {
                                e.preventDefault();
                                setShowBuildings(false);
                            }}
                        >
                            Information
                        </a>
                    </li>
                </ul>
                <div className="border-custom-orange border-solid border-2 p-4 rounded-lg">
                    <p>Selected user: {props.cityGrowth ? props.cityGrowth.accountName : ""}</p>
                    <p>City growth informations</p>
                    {showBuildings ? (
                        <div>
                            {chunkedBuildings.map((chunk, chunkIndex) => (
                                <table key={chunkIndex}>
                                    <thead>
                                    <tr>
                                        <th></th>
                                        {chunk.map((building) => (
                                            !building.hide && (
                                                <th key={building.id} className="p-2">
                                                    <img src={building.src} alt={building.name} className="table-image" />
                                                </th>
                                            )
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {props.cityGrowth ? props.cityGrowth.accountInformation.cityArrayInformation.map((city, rowIndex) => (
                                        <tr key={rowIndex}>
                                            <td className="p-2">{city.name}</td>
                                            {chunk.map((building, colIndex) => {
                                                if (building.hide) return null;
                                                const totalLevel = city.buildings
                                                    .filter(b => b.buildingType === building.name)
                                                    .reduce((acc, b) => acc + b.level, 0);
                                                return (
                                                    <td className="p-2 text-center" key={colIndex}>
                                                        {totalLevel > 0 ? totalLevel : "-"}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    )) : null}
                                    </tbody>
                                </table>
                            ))}
                        </div>
                    ) : (
                        <div>
                            <div>
                                <div>Overall Account Information:</div>
                                <ul>
                                    <li className={"flex"}><img
                                        src={favourIcon}/>: {props.cityGrowth.accountInformation.favour}</li>
                                    <li className={"flex"}><img
                                        src={freighter}/>: {props.cityGrowth.accountInformation.freeFreighters} ({props.cityGrowth.accountInformation.maxFreighters})
                                    </li>
                                    <li className={"flex"}><img
                                        src={transporter}/>: {props.cityGrowth.accountInformation.freeTransporters} ({props.cityGrowth.accountInformation.maxTransporters})
                                    </li>
                                    <li className={"flex"}><img
                                        src={goldIcon}/>: {formatNumberWithCommas(props.cityGrowth.accountInformation.gold)}
                                    </li>
                                    <li className={"flex"}><img src={medalIcon}/> Total
                                        Score: {props.cityGrowth.accountInformation.totalScore}</li>
                                    <li className={"flex"}><img
                                        src={scientistIcon}/>: {props.cityGrowth.accountInformation.scientists}</li>
                                    <li className={"flex"}><img
                                        src={researchPointIcon}/>: {props.cityGrowth.accountInformation.researchPoints}
                                    </li>
                                    <li className={"flex"}><img
                                        src={researchPointPerHourIcon}/>: {props.cityGrowth.accountInformation.researchPointsPerHour}
                                    </li>
                                    <li>Researches</li>
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Seafaring
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Economy
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Science
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Military
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Mythology
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                        <tr>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                {props.cityGrowth.accountInformation.researches.seafaring}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                {props.cityGrowth.accountInformation.researches.economy}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                {props.cityGrowth.accountInformation.researches.knowledge}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                {props.cityGrowth.accountInformation.researches.military}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                {props.cityGrowth.accountInformation.researches.mythology}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </ul>

                                <div>Select a city to see more information:</div>
                                <select value={selectedCity} onChange={handleCityChange}>
                                    {props.cityGrowth.accountInformation.cityArrayInformation.map((city) => (
                                        <option key={city.id} value={city.id}>
                                            {city.name} ({city.coords})
                                        </option>
                                    ))}
                                </select>

                                {selectedCityData && (
                                    <div>
                                        <ul>
                                            <li className={"flex"}>Name: {selectedCityData.name}</li>
                                            <li className={"flex"}><img src={populationIcon}/>: {selectedCityData.totalPopulation} ({selectedCityData.citizen})</li>
                                            <li className={"flex"}><img src={woodIcon}/>: {selectedCityData.wood}</li>
                                            <li className={"flex"}><img src={wineIcon}/>: {selectedCityData.wine}</li>
                                            <li className={"flex"}><img src={marbleIcon}/>: {selectedCityData.marble}</li>
                                            <li className={"flex"}><img src={crystalIcon}/>: {selectedCityData.crystal}</li>
                                            <li className={"flex"}><img src={sulfurIcon}/>: {selectedCityData.sulfur}</li>
                                            <li className={"flex"}><img src={islandIcon}/>: {selectedCityData.coords}</li>
                                            {/*  <li className={"flex"}>Resource Level: {selectedCityData.resourceLevel}</li>*/}
                                            {/*    <li className={"flex"}>Theoretical Max Resource
                                                Worker: {selectedCityData.theoreticalMaxResourceWorker}</li>*/}
                                            {/* <li className={"flex"}>Theoretical Max Trade Good
                                                Worker: {selectedCityData.theoreticalMaxTradeGoodWorker}</li>*/}
                                            {/*       <li className={"flex"}>Trade Good
                                                Level: {selectedCityData.tradeGoodLevel}</li>*/}
                                            <li className={"flex"}>Trade Good
                                                Type: <img src={getResourceIconById(selectedCityData.tradeGoodType)}/></li>

                                            <li className={"flex"}>Wonder Type: <img src={getGodIconById(selectedCityData.wonderType)}/></li>

                                            {/*<li className={"flex"}>Is
                                                Colonizable: {selectedCityData.isColonizable.toString()}</li>*/}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    )
}

export default CityGrowthInformation