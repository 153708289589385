/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import {Form, InputGroup, Modal} from "react-bootstrap";
import {useState} from "react";
import {SERVER_URL} from "../../Constants";

function LicenseManagementConfiguration(props) {

    const [dumie, setDumie] = useState(false)
    const [expirationDateAction,setExpirationDateAction] = useState(0)

    async function updateLicense(){
        if(!props.license) return;

        const payload = {
            license:props.license,
            expirationDateAction:expirationDateAction
        }

        const res = await fetch(SERVER_URL + "/api/admin/editlicense", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(payload)
        });
        const json = await res.json();
        if (json.error) {
            return;
        }else{
            props.closeModal();
        }
    }

    return (
        <div>
            <Modal show={props.showModal} onHide={props.closeModal}>
                <div className={"border-custom-orange border-solid border-2  p-4 rounded-lg top-50"}>
                    <h1 className="font-bold text-xl">License management</h1>
                    <p>{props.license == null ? "none" : props.license.key}</p>
                    <Form>
                        <InputGroup className="mb-3">
                            <InputGroup.Checkbox
                                aria-label="canCityGrowth"
                                checked={props.license ? props.license.canCityGrowth : false}
                                onChange={(event) => {
                                    setDumie(!dumie)
                                    props.license.canCityGrowth = event.target.checked
                                }}
                            />
                            <Form.Control
                                aria-label="canCityGrowth"
                                value={"Can city growth"}
                                disabled
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Checkbox
                                aria-label="canCityTeleport"
                                checked={props.license ? props.license.canCityTeleport : false}
                                onChange={(event) => {
                                    setDumie(!dumie)
                                    props.license.canCityTeleport = event.target.checked
                                }}
                            />
                            <Form.Control
                                aria-label="canCityTeleport"
                                value={"Can city teleport"}
                                disabled
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Checkbox
                                aria-label="canCreateAccount"
                                checked={props.license ? props.license.canCreateAccount : false}
                                onChange={(event) => {
                                    setDumie(!dumie)
                                    props.license.canCreateAccount = event.target.checked
                                }}
                            />
                            <Form.Control
                                aria-label="canCreateAccount"
                                value={"Can create account"}
                                disabled
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Checkbox
                                aria-label="canIslandCheck"
                                checked={props.license ? props.license.canIslandCheck : false}
                                onChange={(event) => {
                                    setDumie(!dumie)
                                    props.license.canIslandCheck = event.target.checked
                                }}
                            />
                            <Form.Control
                                aria-label="canIslandCheck"
                                value={"Can island check"}
                                disabled
                            />
                        </InputGroup><InputGroup className="mb-3">
                        <InputGroup.Checkbox
                            aria-label="canIslandCheckMines"
                            checked={props.license ? props.license.canIslandCheckMines : false}
                            onChange={(event) => {
                                setDumie(!dumie)
                                props.license.canIslandCheckMines = event.target.checked
                            }}
                        />
                        <Form.Control
                            aria-label="canIslandCheckMines"
                            value={"Can island check mines"}
                            disabled
                        />
                    </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Checkbox
                                aria-label="canMilitaryCheck"
                                checked={props.license ? props.license.canMilitaryCheck : false}
                                onChange={(event) => {
                                    setDumie(!dumie)
                                    props.license.canMilitaryCheck = event.target.checked
                                }}
                            />
                            <Form.Control
                                aria-label="canMilitaryCheck"
                                value={"Can military check"}
                                disabled
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Checkbox
                                aria-label="canPirate"
                                checked={props.license ? props.license.canPirate : false}
                                onChange={(event) => {
                                    setDumie(!dumie)
                                    props.license.canPirate = event.target.checked
                                }}
                            />
                            <Form.Control
                                aria-label="canPirate"
                                value={"Can pirate"}
                                disabled
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Checkbox
                                aria-label="canRaiding"
                                checked={props.license ? props.license.canRaiding : false}
                                onChange={(event) => {
                                    setDumie(!dumie)
                                    props.license.canRaiding = event.target.checked
                                }}
                            />
                            <Form.Control
                                aria-label="canRaiding"
                                value={"Can raiding"}
                                disabled
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon3">
                                Max account
                            </InputGroup.Text>
                            <Form.Control onChange={(event) => {
                                setDumie(!dumie)
                                props.license.maxAccount = event.target.value
                            }}
                                          type="number" id="maxAccount"
                                          value={props.license ? props.license.maxAccount : 0}/>
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon3">
                                Discord ID
                            </InputGroup.Text>
                            <Form.Control onChange={(event) => {
                                setDumie(!dumie)
                                props.license.discordId = event.target.value
                            }}
                                          type="number" id="discordId" value={props.license ? props.license.discordId : 0}/>
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon3">
                                Email
                            </InputGroup.Text>
                            <Form.Control onChange={(event) => {
                                setDumie(!dumie)
                                props.license.email = event.target.value
                            }}
                                          type="text" id="email" value={props.license ? props.license.email : ""}/>
                        </InputGroup>
                        <Form.Label>Expiration date: {props.license ? props.license.expireDate : null}</Form.Label>
                        <Form.Control
                            as="select" onChange={(event) => setExpirationDateAction(event.target.value)} value={expirationDateAction}>
                            <option value={0}>No changes</option>
                            <option value={1}>Add 3 days</option>
                            <option value={2}>Add 15 days</option>
                            <option value={3}>Add 30 days</option>
                            <option value={4}>Invalidate</option>
                        </Form.Control>
                    </Form>
                    <button onClick={updateLicense}
                            className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-green-700 rounded">Save
                    </button>
                    <button onClick={props.closeModal}
                            className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-red-700 rounded">Cancel
                    </button>
                </div>

            </Modal>

        </div>
    )

}

export default LicenseManagementConfiguration;