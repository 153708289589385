/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

const actions = {
    init: "INIT",
};


const initialState = {
    token:null,
    isAuth:false,
    licenseInfo:null,
    isAdmin:false,
    isExpired:false
};

const reducer = (state, action) => {
    const { type, data } = action;
    switch (type) {
        case actions.init:
            return { ...state, ...data };
        default:
            throw new Error("Undefined reducer action type");
    }
};

export {
    actions,
    initialState,
    reducer
};