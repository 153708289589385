/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import {Button, Form, InputGroup, Modal, Table} from "react-bootstrap";
import {useEffect, useState} from "react";
import {SERVER_URL} from "../../Constants";
import {notifyFailed, notifySuccess} from "../../ToastHelper";


function LoginInvalidAccountConfiguration(props) {

    const [email, setEmail] = useState("");
    const [accountName, setAccountName] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        console.log(props.invalidAccount)
        if(props.invalidAccount){
            setEmail(props.invalidAccount.email)
            setAccountName(props.invalidAccount.accountName)
            setPassword(props.invalidAccount.password)
        }
    },[props.invalidAccount])

    async function updateData() {

        if (!props.invalidAccount) return;

        let jsonEdit

        if(props.invalidAccount.accountName !== accountName){
            jsonEdit = {
                email: email,
                accountName: accountName,
                password: password,
                serverInfos: props.invalidAccount.serverInfos,
                accountId:props.invalidAccount.accountId,
                accountType:"invalid"
            }
        }else{
            jsonEdit = {
                email: email,
                password: password,
                serverInfos: props.invalidAccount.serverInfos,
                accountId:props.invalidAccount.accountId,
                accountType:"invalid"
            }
        }



        console.log(jsonEdit)

        const res = await fetch(SERVER_URL + "/api/account/setaccount", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonEdit)
        });
        const json = await res.json();
        if (json.error) {
            notifyFailed("An error occured: " + json.error)
        } else {
            notifySuccess("Changes saved")
            props.closeModal()
        }
        console.log(json)

    }

    return (
        <div className={""}>
            <Modal show={props.showModal} onHide={props.closeModal}>
                <div className={"border-custom-orange border-solid border-2  p-4 rounded-lg top-50"}>
                    <h1 className="font-bold text-xl">Invalid account configuration</h1>
                    <p>{props.invalidAccount == null ? "none" : props.invalidAccount.accountName}</p>
                    <Form>
                        <Form.Group controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control value={email} onChange={(event) => setEmail(event.target.value)} type="text"
                                          placeholder="Enter email"/>
                        </Form.Group>
                        <Form.Group controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control value={password} onChange={(event) => setPassword(event.target.value)} type="text"
                                          placeholder="Enter password"/>
                        </Form.Group>
                        <Form.Group controlId="accountName">
                            <Form.Label>Account Name</Form.Label>
                            <Form.Control value={accountName} onChange={(event) => setAccountName(event.target.value)} type="text"
                                          placeholder="Enter accountName"/>
                        </Form.Group>
                    </Form>

                    <button onClick={() => {
                        updateData()
                    }}  className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-green-700 rounded">Save</button>
                    <button onClick={props.closeModal} className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-red-700 rounded">Cancel</button>
                </div>

            </Modal>
        </div>
    )
}

export default LoginInvalidAccountConfiguration;