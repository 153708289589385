/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import {Form, Spinner, ToastHeader} from "react-bootstrap";
import {useEffect, useState} from "react";
import Layout from "../Layout";
import {SERVER_URL} from "../Constants";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactFlagsSelect from "react-flags-select";
import * as ToastHelper from "../ToastHelper";
import {FaSpinner} from "react-icons/fa";
import './../App.css';
import {FiSettings} from "react-icons/fi";
import {MdOutlineLogout,MdOutlineLogin } from "react-icons/md"; // Create this CSS file for custom styles
import {IconContext} from "react-icons";
import IslandCheckConfiguration from "../component/islandcheck/IslandCheckConfiguration";
import LoginInvalidAccountConfiguration from "../component/login/LoginInvalidAccountConfiguration";
import {notifyFailed, notifySuccess} from "../ToastHelper";

function AccountLogin({notifySuccessProps}) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [accountName, setAccountName] = useState('');
    const [mission, setMission] = useState(1);
    const [newLobbyLogin, setNewLobbyLogin] = useState(true)
    const [selectedServer, setSelectedServer] = useState('');
    const [loginHistoryData, setLoginHistoryData] = useState([])
    const [invalidAccountsData, setInvalidAccountsData] = useState([])
    const [invalidAccount, setInvalidAccount] = useState()
    const [requestProcessing, setRequestProcessing] = useState(false)
    const [serverData, setServerData] = useState([])
    const [showConfig, setShowConfig] = useState(false)
    const toastMap = {};

    const languageToCountryCode = {
        en: "GB", // Map "en" to "GB" for the United Kingdom
    };

    const countryCodeToLanguage = {
        GB: "en", // Map "en" to "GB" for the United Kingdom
    };

    const countries = [...new Set(serverData.map(server => languageToCountryCode[server.language] || server.language.toUpperCase()))];
    const [selectedCountry, setSelectedCountry] = useState('');
    const [filteredServers, setFilteredServers] = useState([]);

    const handleCountryChange = (event) => {
        const country = event
        setSelectedCountry(country);
        const parsedCountry = countryCodeToLanguage[country] || country.toLowerCase()
        setFilteredServers(serverData.filter(server => server.language === parsedCountry));
        setSelectedServer(''); // Reset the selected server
    };


    useEffect(() => {
        getIkariamServers()
        getInvalidAccounts()
    }, []);

    const handleEmailChange = event => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = event => {
        setPassword(event.target.value);
    };

    const handleAccountNameChange = event => {
        setAccountName(event.target.value);
    };

    const handleMissionChange = event => {
        setMission(event.target.value);
    };


    async function handleLogin() {
        let jsonCredentials;

        if (email.trim().length === 0) {
            ToastHelper.notifyFailed("Email can't be empty");
            return;
        }

        if (password.trim().length === 0) {
            ToastHelper.notifyFailed("Password can't be empty");
            return;
        }

        if (accountName.trim().length === 0) {
            ToastHelper.notifyFailed("Account name can't be empty");
            return;
        }

        jsonCredentials = {
            email: email,
            accountName: accountName,
            password: password,
            serverInfos: selectedServer,
            missionId: mission
        };

        const loadingToast = toast.info('Logging in ' + accountName + ":" + selectedServer + " ...", {
            icon: <FaSpinner className="spinner"/>, // Custom spinner icon
            hideProgressBar: true,
            autoClose: 180000,
            pauseOnHover: false,
            position: "top-right",
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });

        const toastKey = accountName + ":" + selectedServer;
        toastMap[toastKey] = loadingToast;

        console.log('Initial Toast Map:', toastMap);

        try {
            const res = await fetch(SERVER_URL + "/api/account/accountLogin", {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify(jsonCredentials),
            });
            const json = await res.json();

            console.log('API Response:', json);

            if (json.error) {
                if (toastMap[json.accountNameAndServerInfos]) {
                    toast.update(toastMap[json.accountNameAndServerInfos], {
                        render: json.accountNameAndServerInfos + " => error: " + json.error,
                        type: toast.TYPE.ERROR,
                        autoClose: 5000,
                        hideProgressBar: false,
                        theme: "dark",
                    });
                } else {
                    console.error('Toast key not found in map for error:', json.accountNameAndServerInfos);
                }
            } else {
                if (toastMap[json.accountNameAndServerInfos]) {
                    toast.update(toastMap[json.accountNameAndServerInfos], {
                        render: json.accountNameAndServerInfos + " => success: the account has been logged in",
                        type: toast.TYPE.SUCCESS,
                        autoClose: 5000,
                        hideProgressBar: false,
                        theme: "dark",
                    });
                } else {
                    console.error('Toast key not found in map for success:', json.accountNameAndServerInfos);
                }
            }

            delete toastMap[json.accountNameAndServerInfos];
        } catch (error) {
            console.error('Fetch error:', error);
            toast.update(loadingToast, {
                render: 'An error occurred during the login process',
                type: toast.TYPE.ERROR,
                autoClose: 5000,
                hideProgressBar: false,
                theme: "dark",
            });
        } finally {
            setRequestProcessing(false);
            console.log('Final Toast Map:', toastMap);
        }
    }

    async function handleInvalidLogin(invalidAccount) {
        let jsonCredentials;

        jsonCredentials = {
            email: invalidAccount.email,
            accountName: invalidAccount.accountName,
            password: invalidAccount.password,
            serverInfos: invalidAccount.serverInfos,
            accountId:invalidAccount.accountId,
            missionId: invalidAccount.missionId
        };

        const loadingToast = toast.info('Logging in ' + invalidAccount.accountName + ":" + invalidAccount.serverInfos + " ...", {
            icon: <FaSpinner className="spinner"/>, // Custom spinner icon
            hideProgressBar: true,
            autoClose: 180000,
            pauseOnHover: false,
            position: "top-right",
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });

        const toastKey = invalidAccount.accountName + ":" + invalidAccount.serverInfos;
        toastMap[toastKey] = loadingToast;

        console.log('Initial Toast Map:', toastMap);

        try {
            const res = await fetch(SERVER_URL + "/api/account/invalidaccountLogin", {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify(jsonCredentials),
            });
            const json = await res.json();

            console.log('API Response:', json);

            if (json.error) {
                if (toastMap[json.accountNameAndServerInfos]) {
                    toast.update(toastMap[json.accountNameAndServerInfos], {
                        render: json.accountNameAndServerInfos + " => error: " + json.error,
                        type: toast.TYPE.ERROR,
                        autoClose: 5000,
                        hideProgressBar: false,
                        theme: "dark",
                    });
                } else {
                    console.error('Toast key not found in map for error:', json.accountNameAndServerInfos);
                }
            } else {
                if (toastMap[json.accountNameAndServerInfos]) {
                    toast.update(toastMap[json.accountNameAndServerInfos], {
                        render: json.accountNameAndServerInfos + " => success: the account has been logged in",
                        type: toast.TYPE.SUCCESS,
                        autoClose: 5000,
                        hideProgressBar: false,
                        theme: "dark",
                    });
                } else {
                    console.error('Toast key not found in map for success:', json.accountNameAndServerInfos);
                }
            }

            delete toastMap[json.accountNameAndServerInfos];
        } catch (error) {
            console.error('Fetch error:', error);
            toast.update(loadingToast, {
                render: 'An error occurred during the login process',
                type: toast.TYPE.ERROR,
                autoClose: 5000,
                hideProgressBar: false,
                theme: "dark",
            });
        } finally {
            setRequestProcessing(false);
            console.log('Final Toast Map:', toastMap);
        }
    }



    async function handleLogin2() {

        let jsonCredentials

        if (email.trim().length === 0) {
            ToastHelper.notifyFailed("Email can't be empty")
            return;
        }

        if (password.trim().length === 0) {
            ToastHelper.notifyFailed("Password can't be empty")
            return;
        }

        if (accountName.trim().length === 0) {
            ToastHelper.notifyFailed("Password can't be empty")
            return;
        }

        jsonCredentials = {
            email: email,
            accountName: accountName,
            password: password,
            serverInfos: selectedServer,
            missionId: mission,
        }

        const loadingToast = toast.info('Logging in ' + accountName + ":" + selectedServer + " ...", {
            hideProgressBar: true,
            autoClose: 180000,
            pauseOnHover: false,
            position: "top-right",
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });

        toastMap[accountName + ":" + selectedServer] = loadingToast;

        console.log(toastMap)

        const res = await fetch(SERVER_URL + "/api/account/accountLogin", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonCredentials)
        });
        const json = await res.json();

        if (json.error) {
            toast.update(toastMap[json.accountNameAndServerInfos], {
                render: json.accountNameAndServerInfos + " => error: " + json.error,
                type: toast.TYPE.ERROR,
                autoClose: 5000,
                hideProgressBar: false,
                theme: "dark",
            });
            //notifyFailed("An error occurred during the login of the account")
        } else {
            toast.update(toastMap[json.accountNameAndServerInfos], {
                render: json.accountNameAndServerInfos + " => success: the account has been logged in",
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                theme: "dark",
            });
            //notifySuccess("Success the account has been logged in")
        }
        console.log()
        delete toastMap[json.accountNameAndServerInfos]
        setRequestProcessing(false)

    }

    function borderExample() {
        return <Spinner animation="border"/>;
    }

    async function getInvalidAccounts() {
        const res = await fetch(SERVER_URL + "/api/account/getinvalidaccounts", {
            method: 'GET',
            credentials: 'include',
        });
        const json = await res.json();
        if (json.error) {
            return;
        }
        setInvalidAccountsData(json);
    }

    async function getIkariamServers() {
        const res = await fetch(SERVER_URL + "/api/account/getIkariamServers", {
            method: 'GET',
            credentials: 'include',
        });
        const json = await res.json();
        if (json.error) {
            return;
        }
        setServerData(json);
    }

    async function getLoginHistory() {
        const res = await fetch(SERVER_URL + "/api/lobby/getloginhistory", {
            method: 'GET',
            credentials: 'include',
        });
        const json = await res.json();

        if (json.error) {
            return;
        }
        setLoginHistoryData(json)
    }

    const handleServerChange = (event) => {
        setSelectedServer(event.target.value);
    };

    function timeStampToDate(timestamp) {
        const formatter = new Intl.DateTimeFormat('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        });
        const formattedTimestamp = formatter.format(new Date(timestamp));
        return formattedTimestamp;
    }

    function closeModal() {
        setShowConfig(false)
    }

    function openModal() {
        setShowConfig(true)
    }

    async function logoutAccount(invalidAccount) {

        const jsonObject = {
            accountId: invalidAccount.accountId,
        }
        const res = await fetch(SERVER_URL + "/api/account/logoutinvalidaccount", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonObject)
        });
        let json = await res.json();
        if(json.error){
            notifyFailed("An error occured: " + json.error)
        }else{
            notifySuccess("Account logged out")
            getInvalidAccounts()
        }

    }


    return (
        <div>
            <Layout>
                <ToastContainer/>
                <p>Login account </p>
                <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                    <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                        <div className="flex items-center pl-3">
                            <input id="horizontal-list-radio-id" type="radio"
                                   disabled={requestProcessing}
                                   onChange={(event) => setNewLobbyLogin(event.target.checked)}
                                   checked={newLobbyLogin}
                                   name="list-radio"
                                   className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
                            <label htmlFor="horizontal-list-radio-id"
                                   className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">New
                                account</label>
                        </div>
                    </li>
                </ul>
                <div className="flex flex-col md:flex-row md:justify-between">
                    <div className="md:w-1/2 p-4">
                        <label className="block text-gray-700 font-medium mb-2">
                            Email
                        </label>
                        <input
                            disabled={!newLobbyLogin || requestProcessing}
                            type="email"
                            className="block w-full border border-gray-400 rounded-lg p-2"
                            onChange={handleEmailChange}
                            value={email}
                            placeholder={"email"}

                        />
                        <label className="block text-gray-700 font-medium mt-4 mb-2">
                            Password
                        </label>
                        <input
                            type="password"
                            disabled={!newLobbyLogin || requestProcessing}
                            className="block w-full border border-gray-400 rounded-lg p-2"
                            onChange={handlePasswordChange}
                            value={password}
                            placeholder={"password"}
                        />
                        <label className="block text-gray-700 font-medium mt-4 mb-2">
                            AccountName
                        </label>
                        <input
                            type="text"
                            disabled={!newLobbyLogin || requestProcessing}
                            className="block w-full border border-gray-400 rounded-lg p-2"
                            onChange={handleAccountNameChange}
                            value={accountName}
                            placeholder={"Account name"}
                        />
                        <ReactFlagsSelect
                            className={"mt-4"}
                            searchable={true}
                            searchPlaceholder="Search countries"
                            placeholder="Select Server"
                            selected={selectedCountry}
                            countries={countries}
                            onSelect={handleCountryChange}/>

                        <select value={selectedServer} onChange={handleServerChange}
                                className="block w-full border border-gray-400 rounded-lg p-2" required>
                            <option value="">Select a server</option>
                            {filteredServers.map(server => (
                                <option key={server.accountGroup} value={server.accountGroup}>{server.name}</option>
                            ))}
                        </select>
                        <Form.Group controlId="formBasicMission">
                            <Form.Label>Mission</Form.Label>
                            <Form.Control
                                disabled={!newLobbyLogin || requestProcessing}
                                as="select" onChange={handleMissionChange} value={mission}>
                                <option value={1}>Pirate</option>
                                <option value={3}>Island check</option>
                                <option value={5}>Military check</option>
                                <option disabled={true} value={6}>City teleport</option>
                                <option value={7}>City growth</option>
                            </Form.Control>
                        </Form.Group>
                    </div>
                </div>
                <div className="flex justify-center mt-8">
                    <button
                        disabled={requestProcessing}
                        onClick={handleLogin}
                        className={requestProcessing ? "bg-gray-500 text-white font-medium py-2 px-4 rounded-lg" : "bg-custom-orange hover:bg-orange-700 text-white font-medium py-2 px-4 rounded-lg"}>
                        Login to lobby
                    </button>
                </div>
                <br/>
                <span>Invalid accounts (couldn't log in)</span>
                <LoginInvalidAccountConfiguration showModal={showConfig} invalidAccount={invalidAccount} openModal={openModal}
                                          closeModal={closeModal}/>
                <div>
                    <table className=" min-w-max w-full table-auto rounded-t">
                        <thead>
                        <tr className="bg-custom-orange text-gray-600 uppercase text-sm leading-normal">
                            <th className="py-3 text-center">Email</th>
                            <th className="py-3 text-center">Account name</th>
                            <th className="py-3 text-center">Password</th>
                            <th className="py-3 text-center">Server name</th>
                            <th className="py-3 text-center">Mission</th>
                            <th className="py-3 text-center">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {invalidAccountsData.map((invalidAccount, i) => {
                            return <tr>
                                <td className="py-3 px-6 text-center whitespace-nowrap">{invalidAccount.email}</td>
                                <td className="py-3 px-6 text-center whitespace-nowrap">{invalidAccount.accountName}</td>
                                <td className="py-3 px-6 text-center whitespace-nowrap">{invalidAccount.password}</td>
                                <td className="py-3 px-6 text-center whitespace-nowrap">{invalidAccount.serverName}</td>
                                <td className="py-3 px-6 text-center whitespace-nowrap">{invalidAccount.missionName}</td>
                                <td className="py-3 px-6 text-center whitespace-nowrap">
                                    <div className="flex items-center justify-center">
                                        <IconContext.Provider value={{size: "26px", color: "green"}}>
                                            <MdOutlineLogin onClick={() => handleInvalidLogin(invalidAccount)}
                                                             className={"m-1 hover:cursor-pointer"}/>
                                        </IconContext.Provider>
                                        <IconContext.Provider value={{size: "26px", color: "red"}}>
                                            <MdOutlineLogout onClick={() => logoutAccount(invalidAccount)}
                                                             className={"m-1 hover:cursor-pointer"}/>
                                        </IconContext.Provider>
                                        <IconContext.Provider value={{size: "26px", color: "gray"}}>
                                            <FiSettings
                                                className={"m-1 hover:cursor-pointer"}
                                                onClick={() => {
                                                    setInvalidAccount(invalidAccount)
                                                    setShowConfig(!showConfig)
                                                }}
                                            />
                                        </IconContext.Provider>
                                    </div>

                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                </div>
            </Layout>
        </div>
    )
}

export default AccountLogin;