/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import {IconContext} from "react-icons";
import {FiSettings} from "react-icons/fi";
import {useEffect, useState} from "react";
import {SERVER_URL} from "../../Constants";
import LicenseManagementConfiguration from "./LicenseManagementConfiguration";


function LicenseManagement() {


    const [licenseData, setLicenseData] = useState();
    const [showConfig,setShowConfig] = useState(false);
    const [selectedLicense,setSelectedLicense] = useState();

    function openModal(){
        setShowConfig(true)
    }

    function closeModal(){
        setShowConfig(false)
    }

    async function getData() {

        const res = await fetch(SERVER_URL + "/api/admin/getlicenses", {
            method: 'GET',
            credentials: 'include'
        });
        const json = await res.json();
        if (json.error) {
            return;
        }
        setLicenseData(json);
    }


    useEffect(() => {
        getData()
    }, []);

    return (
        <div>
            Licenses
            <LicenseManagementConfiguration license={selectedLicense} showModal={showConfig} openModal={openModal} closeModal={closeModal}/>
            <table className="min-w-max w-full table-auto rounded-t">
                <thead>
                <tr className="bg-custom-orange text-gray-600 uppercase text-sm leading-normal">
                    <th className="py-3 text-center">License key</th>
                    <th className="py-3  text-center">Discord name</th>
                    <th className="py-3  text-center">Email</th>
                    <th className="py-3  text-center">Actions</th>
                </tr>
                </thead>
                <tbody className="text-gray-600 text-sm font-light">
                {licenseData ? licenseData.map((license) => {
                    return <tr className="border-b border-gray-200 hover:bg-gray-100">
                        <td className="py-3 px-6 text-center whitespace-nowrap">{license.key}</td>
                        <td className="py-3 px-6 text-center whitespace-nowrap">{license.discordName}</td>
                        <td className="py-3 px-6 text-center whitespace-nowrap">{license.email}</td>
                        <td className="py-3 px-6 text-center whitespace-nowrap">
                            <div className="flex items-center justify-center">
                                <IconContext.Provider value={{size: "26px", color: "gray"}}>
                                    <FiSettings
                                        className={"m-1 hover:cursor-pointer"}
                                        onClick={() => {
                                            setSelectedLicense(license)
                                            openModal();
                                        }}
                                    />
                                </IconContext.Provider>
                            </div>
                        </td>
                    </tr>
                }):null}
                </tbody>
            </table>
        </div>
    )

}

export default LicenseManagement