/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import {useEffect, useState} from "react";
import PirateConfiguration from "../component/pirate/PirateConfiguration";
import './../output.css'
import Layout from "../Layout";
import {MdNotStarted, MdOutlineLogout, MdPauseCircle} from "react-icons/md"
import {FiSettings} from "react-icons/fi"
import {IconContext} from "react-icons";
import {SERVER_URL} from "../Constants";
import Raiding from "./Raiding";
import CustomToolTip from "../CustomToolTip";
import {toast} from "react-toastify";

function Pirate() {

    const [pirateData, setPirateData] = useState([]);
    const [selectedPirate, setSelectedPirate] = useState();
    const [showConfig, setShowConfig] = useState(false)
    const [showRaiding, setShowRaiding] = useState(false);

    const courses = [
        [1, 150, "2:30"],
        [2, 450, "7:30"],
        [3, 15 * 60, "15m"],
        [4, 30 * 60, "30m"],
        [5, 60 * 60, "1h"],
        [6, 120 * 60, "2"],
        [7, 240 * 60, "4"],
        [8, 480 * 60, "8h"],
        [9, 960, "16h"],
    ]

    async function getData() {
        const jsonMission = {
            missionId: 1
        }
        const res = await fetch(SERVER_URL + "/api/mission/getmissions", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonMission)
        });
        const json = await res.json();
        const updatedArray = json.map(item => {
            return {...item, progress: getProgress(item)};
        });

        setPirateData(updatedArray)
    }

    function getProgress(pirate) {
        let lastCourse = pirate.lastCourseTimeStamp;
        if (lastCourse == 0) {
            return 0;
        }
        let courseDuration = courses[courses.findIndex(a => a[0] == pirate.courseTypeId)][1]
        const currentTimestamp = new Date().getTime();
        let difference = (currentTimestamp - lastCourse) / 1000
        return difference > courseDuration ? 0 : 100 * difference / courseDuration;

    }

    function updateProgressBar() {
        setPirateData(prevPirateData =>
            prevPirateData.map(pirate => {
                return {
                    ...pirate,
                    progress: getProgress(pirate)
                };
            })
        );
    }

    async function setMissionData(pirateObject) {
        const jsonMission = {
            accountId: pirateObject.accountId,
            action: !pirateObject.isEnabled,
            missionId: 1
        }
        const res = await fetch(SERVER_URL + "/api/mission/setmission", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonMission)
        });
        return await res.json();
    }

    async function logoutAccount(pirate) {

        const jsonObject = {
            accountId: pirate.accountId,
        }
        const res = await fetch(SERVER_URL + "/api/account/logoutaccount", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonObject)
        });
        let json = await res.json();
        if(json.error){
            notifyFailed("An error occured: " + json.error)
        }else{
            notifySuccess("Account logged out")
            getData();
        }

    }

    const notifySuccess = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const notifyFailed = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    function handleOnClick(pirate) {
        let index = pirateData.findIndex(a => a.accountId === pirate.accountId)
        if (index === -1) return;
        const res = setMissionData(pirateData[index])
        if (res.error) {
            console.log("Error while updating")
            return;
        }
        const newPirates = [...pirateData];
        newPirates[index].isEnabled = !newPirates[index].isEnabled;
        setPirateData(newPirates);

    }

    function showModal(){
        setShowConfig(true)
    }

    function closeModal(event){
        if(event) event.preventDefault();
        setShowConfig(false)
    }

    useEffect(() => {
        getData();
        const getDataInterval = setInterval(() => {
            getData();
        }, 15000);

        const updateProgressBarInterval = setInterval(() => {
            updateProgressBar();
        }, 3000);

        return () => {
            clearInterval(getDataInterval)
            clearInterval(updateProgressBarInterval)
        }
    }, []);

    return (
        <div>
            <Layout>
                <ul
                    className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
                    role="tablist"
                >
                    <li className="-mb-px mr-2 last:mr-0 flex-auto text-center hover:cursor-pointer">
                        <a
                            className={
                                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                                (showRaiding === false
                                    ? "text-white bg-custom-orange"
                                    : "text-custom-orange bg-white")
                            }
                            onClick={e => {
                                e.preventDefault();
                                setShowRaiding(false);
                            }}
                        >
                            Pirate
                        </a>
                    </li>
                    <li className="-mb-px mr-2 last:mr-0 flex-auto text-center hover:cursor-pointer">
                        <a
                            className={
                                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                                (showRaiding === true
                                    ? "text-white bg-custom-orange"
                                    : "text-custom-orange bg-white")
                            }
                            onClick={e => {
                                e.preventDefault();
                                setShowRaiding(true);
                            }}
                        >
                            Raiding
                        </a>
                    </li>
                </ul>
                {
                    showRaiding ? <Raiding/> :
                        [

                            <PirateConfiguration selectedPirate={selectedPirate}
                                                 updateData={getData}
                                                 showModal={showConfig}
                                                 openModal={showModal}
                                                 closeModal={closeModal}/>,
                            <div>
                                <h1>Pirate</h1>
                                <table className="min-w-max w-full table-auto rounded-t">
                                    <thead>
                                    <tr className="bg-custom-orange text-gray-600 uppercase text-sm leading-normal">
                                        <th className="py-3 text-center">Email</th>
                                        <th className="py-3  text-center">Account name</th>
                                        <th className="py-3  text-center">Course</th>
                                        <th className="py-3  text-center">Progress</th>
                                        <th className="py-3  justify-center"><img className="m-auto"
                                                                                  src={require('./../assets/capturePoints.png')}/>
                                        </th>
                                        <th className="py-3  justify-center"><img className="m-auto"
                                                                                  src={require('./../assets/pirateCrew.png')}/>
                                        </th>
                                        <th className="py-3  text-center">Convert crew</th>
                                        <th className="py-3  text-center">Stop limit</th>
                                        <th className="py-3  text-center">Actions</th>

                                    </tr>
                                    </thead>
                                    <tbody className="text-gray-600 text-sm font-light">
                                    {pirateData.map((pirate) => {
                                        return <tr className="border-b border-gray-200 hover:bg-gray-100">

                                            {pirate.notifications.length > 0 ?
                                                <td>
                                                    <div className="p-1 flex items-center justify-center">
                                                        <CustomToolTip text={pirate.notifications}/>
                                                        {pirate.email}
                                                    </div>
                                                </td> : <td>
                                                    {pirate.email}
                                                </td>}
                                            <td className="py-3 px-6 text-center whitespace-nowrap">{pirate.accountName}</td>
                                            <td className="py-3 px-6 text-center whitespace-nowrap">{courses[courses.findIndex(a => a[0] == pirate.courseTypeId)][2]}</td>
                                            <td className="py-3 text-center whitespace-nowrap">
                                                <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                                    <div className="bg-green-600 h-2.5 rounded-full dark:bg-green-500"
                                                         style={{"width": " " + pirate.progress + "%"}}></div>
                                                </div>
                                            </td>
                                            <td className="py-3 px-6 text-center whitespace-nowrap">{pirate.capturePoints}</td>
                                            <td className="py-3 px-6 text-center whitespace-nowrap">{pirate.crew}</td>
                                            <td className="py-3 px-6 text-center whitespace-nowrap">{pirate.shouldConvertCrew.toString()}</td>
                                            <td className="py-3 px-6 text-center whitespace-nowrap">{pirate.shouldStopAtLimit ? pirate.shouldStopAtLimit + " (" + pirate.pirateStopLimitCurrent + "/" + pirate.pirateStopLimit + ")" : pirate.shouldStopAtLimit.toString()}</td>
                                            <td className="py-3 px-6 text-center whitespace-nowrap">
                                                <div className="flex items-center justify-center">
                                                    {pirate.isEnabled ?
                                                        <IconContext.Provider value={{size: "26px", color: "green"}}>
                                                            <MdPauseCircle onClick={() => handleOnClick(pirate)}
                                                                           className={"m-1 hover:cursor-pointer"}/>
                                                        </IconContext.Provider> : null}

                                                    <IconContext.Provider value={{size: "26px", color: "red"}}>
                                                        {pirate.isEnabled ? null :
                                                            <MdNotStarted onClick={() =>

                                                                handleOnClick(pirate)
                                                            }
                                                                          className={"m-1 hover:cursor-pointer"}/>}
                                                        <MdOutlineLogout onClick={() => logoutAccount(pirate)}
                                                                         className={"m-1 hover:cursor-pointer"}/>
                                                    </IconContext.Provider>
                                                    <IconContext.Provider value={{size: "26px", color: "gray"}}>
                                                        <FiSettings
                                                            className={"m-1 hover:cursor-pointer"}
                                                            onClick={() => {
                                                                setSelectedPirate(pirate)
                                                                setShowConfig(!showConfig)
                                                            }}
                                                        />
                                                    </IconContext.Provider>
                                                </div>
                                            </td>
                                        </tr>
                                    })}
                                    </tbody>
                                </table>

                            </div>
                        ]
                }
            </Layout>
        </div>
    )
}

export default Pirate;