/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */
import {AiFillDashboard, AiFillSetting, AiOutlineLogin, AiOutlinePlusCircle} from "react-icons/ai";
import {GiIsland, GiPirateFlag, GiTeleport} from "react-icons/gi";
import {MdAdminPanelSettings, MdLocationSearching, MdOutlineLogout} from "react-icons/md";
import {FaCity} from "react-icons/fa";
import Images from "./Images";

export const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const MENUS = [
    { title: "Dashboard", src: AiFillDashboard, url:"/",},
    { title: "Account login", src: AiOutlineLogin ,gap: true,url:'/feature/accountLogin'},
    { title: "Pirate", src: GiPirateFlag,url: "/feature/pirate", permission:'canPirate'},
    { title: "Island check", src: GiIsland, url:"/feature/islandCheck",permission:'canIslandCheck'},
    { title: "Account creation", src: AiOutlinePlusCircle,url: "/feature/accountCreation",permission:'canCreateAccount'},
    { title: "City teleport", src: GiTeleport,url: "/feature/cityTeleport",permission:'canCityTeleport' },
    { title: "Military check", src: MdLocationSearching,url:"/feature/militaryCheck",permission:'canMilitaryCheck'},
    { title: "City growth ", src: FaCity,url: "/feature/cityGrowth",permission:'canCityGrowth'},
    { title: "Settings", src: AiFillSetting , gap: true,url:"/settings"},
    { title: "Admin panel", src: MdAdminPanelSettings ,url:"/admin" , adminOnly:true },
    { title: "Logout", src: MdOutlineLogout , gap: true, url:"/logout" },
];

export const JSON_BUILDINGS = [
    {name: "Pirate Fortress", id: 30, maxQuantity: 1, isUnique: false,src:Images.pirateFortress},
    {name: "Palace", id: 11, maxQuantity: 1, isUnique: false,src:Images.palace},
    {name: "Trading Port", id: 3, maxQuantity: 2, isUnique: false,src:Images.tradingPort},
    {name: "Trading Post", id: 13, maxQuantity: 1, isUnique: false,src:Images.tradingPost},
    {name: "Governor`s Residence", id: 17, maxQuantity: 1, isUnique: false,src:Images.governorResidence},
    {name: "Architect`s Office", id: 24, maxQuantity: 1, isUnique: false,src:Images.architectOffice},
    {name: "Glassblower", id: 20, maxQuantity: 1, isUnique: false,src:Images.glassblower,hideInPlanConfig:true},
    {name: "Wine press", id: 26, maxQuantity: 1, isUnique: false,src:Images.winePress},
    {name: "Barracks", id: 6, maxQuantity: 1, isUnique: false,src:Images.barracks},
    {name: "Warehouse", id: 7, maxQuantity: 5, isUnique: false,src:Images.warehouse},
    {name: "Academy", id: 4, maxQuantity: 1, isUnique: false,src:Images.academy},
    {name: "Town Hall", id: 0, maxQuantity: 1, isUnique: false,src:Images.townHall},
    {name: "Town Wall", id: 8, maxQuantity: 1, isUnique: false,src:Images.townWall},
    {name: "Shipyard", id: 5, maxQuantity: 1, isUnique: false,src:Images.shipyard},
    {name: "Workshop", id: 15, maxQuantity: 1, isUnique: false,src:Images.workshop},
    {name: "Optician", id: 25, maxQuantity: 1, isUnique: false,src:Images.optician},
    {name: "Winery", id: 21, maxQuantity: 1, isUnique: false,src:Images.winery,hideInPlanConfig:true},
    {name: "Forester`s House", id: 18, maxQuantity: 1, isUnique: false,src:Images.foresterHouse},
    {name: "Carpenter`s Workshop", id: 23, maxQuantity: 1, isUnique: false,src:Images.carpenter},
    {name: "Hideout", id: 16, maxQuantity: 1, isUnique: false,src:Images.hideout},
    {name: "Tavern", id: 9, maxQuantity: 1, isUnique: false,src:Images.tavern},
    {name: "Museum", id: 10, maxQuantity: 1, isUnique: false,src:Images.museum},
    {name: "Stonemason", id: 19, maxQuantity: 1, isUnique: false,src:Images.stonemason,hideInPlanConfig:true},
    {name: "Depot", id: 29, maxQuantity: 3, isUnique: false,src:Images.depot},
    {name: "Embassy", id: 12, maxQuantity: 1, isUnique: true,src:Images.embassy},
    {name: "Black market", id: 31, maxQuantity: 1, isUnique: true,src:Images.blackMarket},
    {name: "Alchemist`s Tower", id: 22, maxQuantity: 1, isUnique: false,src:Images.alchemistTower,hideInPlanConfig:true},
    {name: "Firework Test Area", id: 27, maxQuantity: 1, isUnique: false,src:Images.fireworkTestArea},
    {name: "Sea Chart Archive", id: 32, maxQuantity: 1, isUnique: false,src:Images.seaChartArchive},
    {name: "Temple", id: 28, maxQuantity: 1, isUnique: false,src:Images.temple},
    {name: "Luxery Boosters (Winery, Stonemason ect...)", id: 40, maxQuantity: 1, isUnique: false,src:Images.foresterHouse, hide:true},
    {name: "Dockyard", id: 33, maxQuantity: 1, isUnique: false,src:Images.dockyard},
    {name: "Gods’ Shrine", id: 34, maxQuantity: 1, isUnique: true,src:Images.godsShrine}
];