/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import {Link} from "react-router-dom";
function Header(){
    return(<div className="w-full container mx-auto">
        <div className="w-full flex items-center justify-between">
            <Link to="/">
                        <span
                            className="flex items-center text-indigo-400 no-underline hover:no-underline font-bold text-2xl lg:text-4xl">
                            IkaOracle
                        </span>
            </Link>

            <div className="flex w-1/2 justify-end content-center">
                {/*<Link to="/pricing">*/}
                {/*    <button*/}
                {/*        className="px-3 mr-5 border border-white border-solid border-1 rounded inline-block text-blue-300 no-underline hover:text-pink-500 hover:text-underline text-center h-10 p-2 md:h-auto md:p-4 transform hover:scale-125 duration-300 ease-in-out"*/}
                {/*    >Pricing*/}
                {/*    </button>*/}
                {/*</Link>*/}
                <Link to="/login">
                    <button
                        className="px-3 border border-white border-solid border-1 rounded inline-block text-blue-300 no-underline hover:text-pink-500 hover:text-underline text-center h-10 p-2 md:h-auto md:p-4 transform hover:scale-125 duration-300 ease-in-out"
                    >Login
                    </button>
                </Link>
            </div>
        </div>
    </div>
    )
}

export default Header;