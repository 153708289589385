/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import {Modal} from "react-bootstrap";
import {SERVER_URL} from "../../Constants";
import {useEffect, useState} from "react";
import {IconContext} from "react-icons";
import {RxCrossCircled} from "react-icons/rx";
import {toast} from "react-toastify";


function RaidingConfiguration(props) {


    const [loadedRaiderPirateCities, setLoadedRaiderPirateCities] = useState([]);
    const [loadedRaidedPirateCities, setLoadedRaidedPirateCities] = useState([]);
    const [selectedRaiderIndex, setSelectedRaiderIndex] = useState(0)
    const [selectedRaidedIndex, setSelectedRaidedIndex] = useState(0)
    const [timeToWait, setTimeToWait] = useState(0);

    async function getRaiderPirateCities() {
        const res = await fetch(SERVER_URL + "/api/raid/getpiracycities", {
            method: 'POST',
            credentials: 'include',
            body: '{}'
        });
        const json = await res.json();
        console.log(json)
        if (json.error) {

        } else {
            setLoadedRaiderPirateCities(json)
            if (json.length > 0) getRaidedPirateCities(json, 0)
        }

    }

    async function getRaidedPirateCities(value, index) {
        let jsonObject;

        if (value == '') {
            jsonObject = {
                cityId: loadedRaiderPirateCities[index].cityId
            }
        } else {
            jsonObject = {
                cityId: value[index].cityId
            }
        }


        if (!jsonObject.cityId) return;

        const res = await fetch(SERVER_URL + "/api/raid/getpiracycities", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonObject)
        });
        const json = await res.json();
        console.log(json)
        if (json.error) {

        } else {
            setLoadedRaidedPirateCities(json)
        }

    }

    async function editChainRaid(){

        if(!props.chainRaid) return;

        const res = await fetch(SERVER_URL + "/api/raid/editchainraid", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(props.chainRaid)
        });
        const json = await res.json();
        console.log(json)
        if(json.error){
            notifyFailed("An error occured: " + json.error)
        }else{
            notifySuccess("Chain saved")
            props.updateData();
            props.closeModal();
        }
    }

    const notifySuccess = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const notifyFailed = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    function addRaid() {
        if (loadedRaiderPirateCities[selectedRaiderIndex] == null || loadedRaidedPirateCities[selectedRaidedIndex] == null) return;

        console.log(loadedRaiderPirateCities[selectedRaiderIndex])
        console.log(loadedRaidedPirateCities[selectedRaidedIndex])

        const raidJson = {
            raiderCity: loadedRaiderPirateCities[selectedRaiderIndex],
            raidedCity: loadedRaidedPirateCities[selectedRaidedIndex],
            timeToWait: timeToWait
        }


        props.setChainRaid(raidJson);

    }

    useEffect(() => {
        getRaiderPirateCities();
    }, []);

    return (<div>
            <p>Raiding</p>
            <Modal show={props.showModal} onHide={props.closeModal}>
                <Modal.Header>
                    <Modal.Title>{props.chainRaid ? props.chainRaid.name : null}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label className="block text-gray-700 font-medium mb-2">
                        Raider city
                    </label>
                    <select
                        disabled={loadedRaiderPirateCities.length <= 0}
                        onChange={(event) => {
                            setSelectedRaiderIndex(
                                loadedRaiderPirateCities.findIndex((val) => val.cityId == event.target.value)
                            );
                            getRaidedPirateCities('', loadedRaiderPirateCities.findIndex((val) => val.cityId == event.target.value))
                        }}
                        value={loadedRaiderPirateCities ? loadedRaiderPirateCities[selectedRaiderIndex]?.cityId ?? null : null
                        }>
                        {loadedRaiderPirateCities.map((city, index) => {
                            return (
                                <option key={city.cityId} value={city.cityId}>
                                    {city.cityName} ({city.cityOwner}) - {city.coords}
                                </option>
                            );
                        })}
                    </select>
                    <label className="block text-gray-700 font-medium mb-2">
                        Raided city
                    </label>
                    <select
                        disabled={loadedRaidedPirateCities.length <= 0}
                        onChange={(event) => {

                            setSelectedRaidedIndex(
                                loadedRaidedPirateCities.findIndex((val) => val.cityId == event.target.value)
                            );
                        }}
                        value={loadedRaidedPirateCities ? loadedRaidedPirateCities[selectedRaidedIndex]?.cityId ?? null : null
                        }>
                        {loadedRaidedPirateCities.map((city, index) => {
                            return (
                                <option key={city.cityId} value={city.cityId}>
                                    {city.cityName} ({city.cityOwner}) - {city.coords}
                                </option>
                            );
                        })}
                    </select>
                    <br/>
                    <label className="block text-gray-700 font-medium mt-4 mb-2">
                        Time to wait
                    </label>
                    <input
                        type="number"
                        className="block w-full border border-gray-400 rounded-lg p-2"
                        onChange={(event) => setTimeToWait(event.target.value)}
                        value={timeToWait}
                        placeholder={"time to wait"}
                    />
                    <button
                        onClick={addRaid}
                        className={"bg-custom-orange hover:bg-orange-700 text-white font-medium py-2 px-4 rounded-lg"}>
                        Add raid
                    </button>
                    <table className="min-w-max w-full table-auto rounded-t">
                        <thead>
                        <tr className="bg-custom-orange text-gray-600 uppercase text-sm leading-normal">
                            <th className="py-3  text-center">Raider city</th>
                            <th className="py-3  text-center">Raided city</th>
                            <th className="py-3  text-center">Time to wait</th>
                            <th className="py-3  text-center">Actions</th>

                        </tr>
                        </thead>
                        <tbody className="text-gray-600 text-sm font-light">
                        {props.chainRaid ? props.chainRaid.raids.map((raid, index) => {
                            return <tr key={raid.name} className="border-b border-gray-200 hover:bg-gray-100">

                                <td className="py-3 px-6 text-center whitespace-nowrap">{raid.raiderCity.cityName} ({raid.raiderCity.cityOwner})</td>
                                <td className="py-3 px-6 text-center whitespace-nowrap">{raid.raidedCity.cityName} ({raid.raidedCity.cityOwner})</td>
                                <td className="py-3 px-6 text-center whitespace-nowrap">{raid.timeToWait}</td>
                                <td className="py-3 px-6 text-center whitespace-nowrap">{
                                    <IconContext.Provider value={{size: "26px", color: "red"}}>
                                        <RxCrossCircled className={"m-1 hover:cursor-pointer"}  onClick={() => props.removeRaidFromChain(index)}/>
                                    </IconContext.Provider>}</td>

                            </tr>
                        }) : null}
                        </tbody>
                    </table>
                </Modal.Body>

                <Modal.Footer>
                    <button className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-green-700 rounded" onClick={editChainRaid}>
                        Save Changes
                    </button>
                    <button className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-red-700 rounded" onClick={props.closeModal}>
                        Close
                    </button>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default RaidingConfiguration;