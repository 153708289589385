/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import {SERVER_URL} from "../Constants";
import {useEffect, useState} from "react";
import {BsArrowsExpand} from "react-icons/bs";
import Collapse from "@kunukn/react-collapse";
import {IconContext} from "react-icons";
import {MdNotStarted, MdPauseCircle} from "react-icons/md";
import {FiSettings} from "react-icons/fi";
import RaidingConfiguration from "../component/pirate/RaidingConfiguration";
import {RxCrossCircled} from "react-icons/rx";
import {toast} from "react-toastify";


function Raiding() {

    const [raidingData, setRaidingData] = useState([]);
    const [chainName, setChainName] = useState('');
    const [showChainCreation, setChainCreation] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [selectedChainRaid, setSelectedChainRaid] = useState()

    async function getData() {
        const res = await fetch(SERVER_URL + "/api/raid/getchainraid", {
            method: 'GET',
            credentials: 'include',
        });
        const json = await res.json();
        if (json.error) {

        } else {
            setRaidingData(json)
        }

    }

    const notifySuccess = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const notifyFailed = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    async function createChain() {

        if(chainName.trim().length === 0){
            notifyFailed("Chain name can't be empty")
        }

        let jsonObject = {
            name: chainName
        }

        const res = await fetch(SERVER_URL + "/api/raid/createchainraid", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonObject)
        });
        const json = await res.json();
        if(json.error){
            notifyFailed("An error occured: " + json.error)
        }else{
            notifySuccess("Chain created")
            getData()
        }

    }

    function closeModal() {
        setShowModal(false);
    }

    async function startChain(raid){
        let jsonObject = {
            name: raid.name
        }
        const res = await fetch(SERVER_URL + "/api/raid/startchainraid", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonObject)
        });
        const json = await res.json();
        if(json.error){
            notifyFailed("An error occured: " + json.error)
        }else{
            notifySuccess(json.status)
            getData()
        }
    }

    async function stopChain(raid){
        let jsonObject = {
            name: raid.name
        }
        const res = await fetch(SERVER_URL + "/api/raid/stopchainraid", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonObject)
        });
        const json = await res.json();
        if(json.error){
            notifyFailed("An error occured: " + json.error)
        }else{
            notifySuccess("Chain started")
            getData()
        }
    }

    function addRaidToChain(raid) {
        setSelectedChainRaid(prevState => ({
            ...prevState,
            raids: [...prevState.raids, raid]
        }));
    }

    async function deleteChain(raid){
        let jsonObject = {
            name: raid.name
        }
        const res = await fetch(SERVER_URL + "/api/raid/removeraidchain", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonObject)
        });
        const json = await res.json();
        if(json.error){
            notifyFailed("An error occured: " + json.error)
        }else{
            notifySuccess("Chain deleted")
            getData()
        }

    }

    function removeRaidFromChain(index) {
        setSelectedChainRaid(prevChainRaid => {
            if (index === -1) {
                return prevChainRaid; // element not found, return previous state
            }
            const newRaids = [...prevChainRaid.raids]; // create a copy of raids
            newRaids.splice(index, 1); // remove the element at the index
            return { ...prevChainRaid, raids: newRaids }; // return a new object with the modified raids array
        });
    }

    function openModal() {
        setShowModal(true);
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <div>
            <RaidingConfiguration updateData={getData} removeRaidFromChain={removeRaidFromChain} setChainRaid={addRaidToChain} chainRaid={selectedChainRaid} showModal={showModal} closeModal={closeModal} openModal={openModal}/>
            <div className={"border-2 border-custom-orange rounded-lg p-2 my-1"}>
                <button className={"bg-custom-orange rounded-lg p-1 flex items-center justify-center mb-2"}
                        onClick={() => setChainCreation(!showChainCreation)}>
                    <span className="mr-2">Chain creation</span>
                    <BsArrowsExpand/>
                </button>
                <Collapse isOpen={showChainCreation}>
                    <label className="block text-gray-700 font-medium mb-2">
                        Chain name
                    </label>
                    <input
                        type="text"
                        className="block w-full border border-gray-400 rounded-lg p-2"
                        onChange={event => setChainName(event.target.value)}
                        value={chainName}
                        placeholder={"chain name"}
                    />
                    <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                            onClick={createChain}>Create chain
                    </button>
                </Collapse>
            </div>

            <table className="min-w-max w-full table-auto rounded-t">
                <thead>
                <tr className="bg-custom-orange text-gray-600 uppercase text-sm leading-normal">
                    <th className="py-3 text-center">Name</th>
                    <th className="py-3  text-center">Actions</th>

                </tr>
                </thead>
                <tbody className="text-gray-600 text-sm font-light">
                {raidingData.map((chainRaid) => {
                    return <tr className="border-b border-gray-200 hover:bg-gray-100">

                        <td className="py-3 px-6 text-center whitespace-nowrap">{chainRaid.name}</td>

                        <td className="py-3 px-6 text-center whitespace-nowrap">
                            <div className="flex items-center justify-center">
                                {chainRaid.isEnabled ?
                                    <IconContext.Provider value={{size: "26px", color: "green"}}>
                                        <MdPauseCircle onClick={() => stopChain(chainRaid)}
                                                       className={"m-1 hover:cursor-pointer"}/>
                                    </IconContext.Provider> : null}

                                <IconContext.Provider value={{size: "26px", color: "red"}}>
                                    {chainRaid.isEnabled ? null :
                                        <MdNotStarted onClick={() => startChain(chainRaid)}
                                                      className={"m-1 hover:cursor-pointer"}/>}
                                    <RxCrossCircled onClick={() => deleteChain(chainRaid)}
                                                     className={"m-1 hover:cursor-pointer"}/>
                                </IconContext.Provider>
                                <IconContext.Provider value={{size: "26px", color: "gray"}}>
                                    <FiSettings
                                        className={"m-1 hover:cursor-pointer"}
                                        onClick={() => {
                                            setSelectedChainRaid(chainRaid)
                                            openModal()
                                        }}
                                    />
                                </IconContext.Provider>
                            </div>
                        </td>
                    </tr>
                })}
                </tbody>
            </table>

        </div>
    )
}

export default Raiding;