/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import {Table} from "react-bootstrap";
import {useEffect, useState} from "react";
import '../index.css'
import CityTeleportConfiguration from "../component/cityteleport/CityTeleportConfiguration";
import Layout from "../Layout";
import {IconContext} from "react-icons";
import {MdNotStarted, MdOutlineLogout, MdPauseCircle} from "react-icons/md";
import {FiSettings} from "react-icons/fi";
import {SERVER_URL} from "../Constants";

function CityTeleport(props) {

    const [cityTeleportData, setCityTeleportData] = useState([]);
    const [selectedCityTeleport, setSelectedCityTeleport] = useState();
    const [showConfig, setShowConfig] = useState(false)

    async function getData() {
        const jsonMission = {
            missionId: 6
        }
        const res = await fetch(SERVER_URL + "/api/mission/getmissions", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonMission)
        });
        const json = await res.json();
        if(json.error){
            return;
        }
        setCityTeleportData(json)
        console.log(json)
    }


    useEffect(() => {
        console.log("trigger")
        getData()
    }, []);

    function getIslandCheckIndexFromList(accountId) {
        let index = 0;
        for (const element of cityTeleportData) {
            console.log(element)
            if (element.accountId === accountId) {
                return index
            }
            index++;
        }
        return -1;
    }


    async function setMissionData(cityTeleportObject) {
        return;
        const jsonMission = {
            accountId: cityTeleportObject.accountId,
            action: !cityTeleportObject.isEnabled,
            missionId: 6
        }
        const res = await fetch(SERVER_URL + "/api/mission/setmission", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonMission)
        });
        return await res.json();
    }

    async function logoutAccount(cityTeleport) {

        const jsonObject = {
            accountId: cityTeleport.accountId,
        }
        const res = await fetch(SERVER_URL + "/api/account/logoutaccount", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonObject)
        });
        let jsonRes = await res.json();
        console.log(jsonRes)
        getData();
    }

    function handleOnClick(islandCheck) {

        let index = getIslandCheckIndexFromList(islandCheck.accountId)
        if (index === -1) return;
        const res = setMissionData(cityTeleportData[index])
        if (res.error) {
            console.log("Error while updating")
            return;
        }
        const newCityTeleport = [...cityTeleportData];
        newCityTeleport[index].isEnabled = !newCityTeleport[index].isEnabled;
        setCityTeleportData(newCityTeleport);

    }

    return (
        <div>
            <Layout>
                {showConfig ? <CityTeleportConfiguration cityTeleport={selectedCityTeleport}/> : null}
            <p>Island Check</p>
                <table className=" min-w-max w-full table-auto rounded-t">
                    <thead>
                    <tr className="bg-custom-orange text-gray-600 uppercase text-sm leading-normal">
                    <th className="py-3 text-center">Email</th>
                    <th className="py-3 text-center">Account name</th>
                    <th className="py-3 text-center">Server infos</th>
                    <th className="py-3 text-center">Island list</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {cityTeleportData ? cityTeleportData.map((cityTeleport) => {
                    return <tr onClick={() => setSelectedCityTeleport(cityTeleport)}>
                        <td>{cityTeleport.email}</td>
                        <td>{cityTeleport.accountName}</td>
                        <td>{cityTeleport.serverInfo}</td>
                        <td>{cityTeleport.cityTeleportList.map((cityTp) => {
                            return <p>{cityTp.sourceCityName} -> {cityTp.targetIslandCoords} - {cityTp.targetPosition}</p>
                        })}</td>
                        <td className="py-3 px-6 text-center whitespace-nowrap">
                            <div className="flex items-center justify-center">
                                {cityTeleport.isEnabled ?
                                    <IconContext.Provider value={{size: "26px", color: "green"}}>
                                        <MdPauseCircle onClick={() => handleOnClick(cityTeleport)}
                                                       className={"m-1 hover:cursor-pointer"}/>
                                    </IconContext.Provider> : null}

                                <IconContext.Provider value={{size: "26px", color: "red"}}>
                                    {cityTeleport.isEnabled ? null :
                                        <MdNotStarted onClick={() => handleOnClick(cityTeleport)}
                                                      className={"m-1 hover:cursor-pointer"}/>}
                                    <MdOutlineLogout onClick={() => logoutAccount(cityTeleport)}
                                                     className={"m-1 hover:cursor-pointer"}/>
                                </IconContext.Provider>
                                <IconContext.Provider value={{size: "26px", color: "gray"}}>
                                    <FiSettings
                                        className={"m-1 hover:cursor-pointer"}
                                        onClick={() => {
                                            setSelectedCityTeleport(cityTeleport)
                                            setShowConfig(!showConfig)
                                        }}
                                    />
                                </IconContext.Provider>
                            </div>
                        </td>
                    </tr>
                }) : null}
                </tbody>
                </table>
            </Layout>
        </div>
    )
}

export default CityTeleport;