/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Dashboard from "./feature/Dashboard";
import Login from "./feature/home/Login";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import useAuth from "./auth/useAuth";
import Pirate from "./feature/Pirate";
import AccountLogin from "./feature/AccountLogin";
import Raiding from "./feature/Raiding";
import IslandCheck from "./feature/IslandCheck";
import AccountCreation from "./feature/AccountCreation";
import CityTeleport from "./feature/CityTeleport";
import MilitaryCheck from "./feature/MilitaryCheck";
import CityGrowth from "./feature/CityGrowth";
import Home from "./feature/home/Home";
import Pricing from "./feature/home/Pricing";
import AdminPanel from "./feature/admin/AdminPanel";

import Settings from "./component/Settings";
import Payment from "./feature/Payment";
import {useEffect} from "react";
import Testcitygrowth from "./feature/Testcitygrowth";

function App() {

    const {state} = useAuth();

    return (
        <Router>
            <Routes>
                {state.isAuth ? (
                    [
                        <Route path="/feature/pirate" element={<Pirate/>}/>,
                        <Route path="/feature/accountLogin" element={<AccountLogin/>}/>,
                        <Route path="/feature/raiding" element={<Raiding/>}/>,
                        <Route path="/feature/islandCheck" element={<IslandCheck/>}/>,
                        <Route path="/feature/accountCreation" element={<AccountCreation/>}/>,
                        <Route path="/feature/cityTeleport" element={<CityTeleport/>}/>,
                        <Route path="/feature/militaryCheck" element={<MilitaryCheck/>}/>,
                        <Route path="/feature/cityGrowth" element={<CityGrowth/>}/>,
                        <Route path="/feature/cityGrowth2" element={<Testcitygrowth/>}/>,
                        <Route path="/settings" element={<Settings/>}/>,
                        <Route path="/admin" element={<AdminPanel/>}/>,
                        <Route path="/payment" element={<Payment/>}/>,
                        <Route path="/" element={<Dashboard/>}/>,
                        <Route path="*" element={<Navigate to="/" />} />
                    ]
                ) : (
                    [
                        <Route path="/login" element={<Login/>}/>,
                        <Route path="/pricing" element={<Pricing/>}/>,
                        <Route path="/" element={<Home/>}/>,
                        <Route path="*" element={<Navigate to="/" />} />
                    ]
                )}
            </Routes>
        </Router>
    );
}

export default App;
