/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import {useEffect, useState} from "react";
import {Form, InputGroup, Modal} from "react-bootstrap";
import {SERVER_URL} from "../../Constants";
import {toast} from "react-toastify";

function CityGrowthConfiguration(props) {


    const [cityGrowthPlans, setCityGrowthPlans] = useState();
    const [selectedCityGrowthPlanName, setSelectedCityGrowthPlanName] = useState();
    const [selectedGodsBlessingId, setSelectedGodsBlessingId] = useState();
    const [selectedShippingDonorCity, setSelectedShippingDonorCity] = useState();
    const [selectedShippingReceiverCity, setSelectedShippingReceiverCity] = useState();
    const [dumie, setDumie] = useState(false)


    const GodEnum = {
        PAN: 1,
        DIONYSUS: 2,
        TYCHE: 3,
        PLUTUS: 4,
        THEIA: 5,
        HEPHAESTUS: 6
    };

    const godMapping = {
        1: "PAN",
        2: "DIONYSUS",
        3: "TYCHE",
        4: "PLUTUS",
        5: "THEIA",
        6: "HEPHAESTUS"
    };

    async function updateData() {

        if (!props.cityGrowth) return;

        let jsonEdit = {
            cityGrowthPlanName: selectedCityGrowthPlanName,
            accountId: props.cityGrowth.accountId,
            shouldBoostProd: props.cityGrowth.shouldBoostProd,
            shouldDonateOnly: props.cityGrowth.shouldDonateOnly,
            shouldOvercharge: props.cityGrowth.shouldOvercharge,
            shouldGoldOnly: props.cityGrowth.shouldGoldOnly,
            shouldKeepFaith: props.cityGrowth.shouldKeepFaith,
            doResearches: props.cityGrowth.doResearches,
            putSearchers: props.cityGrowth.putSearchers,
            shouldShipResources: props.cityGrowth.shouldShipResources,
            applyGodsBlessing: props.cityGrowth.applyGodsBlessing,
            godBlessingId: parseInt(selectedGodsBlessingId),
            shouldBuild: props.cityGrowth.shouldBuild,
            shippingDonorList: props.cityGrowth.shippingDonorList.map(obj => obj.cityId),
            shippingReceiverList: props.cityGrowth.shippingReceiverList.map(obj => obj.cityId),
        }

        const res = await fetch(SERVER_URL + "/api/account/setaccount", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonEdit)
        });
        const json = await res.json();
        console.log("trigger")
        if (json.error) {
            notifyFailed("An error occured: " + json.error)
        } else {
            notifySuccess("Changes saved")
            props.closeModal()
        }

    }

    const notifySuccess = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const notifyFailed = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    function isJsonObject(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    async function addShippingDonor(event, city) {

        event.preventDefault()

        const valueToBeSent = isJsonObject(city) ? JSON.parse(city) : city;

        const hasItemWithName = props.cityGrowth.shippingDonorList.some(donorCity => donorCity.cityId === valueToBeSent.cityId);

        if (hasItemWithName) return;

        props.cityGrowth.shippingDonorList.push(valueToBeSent)

        setDumie(!dumie)

    }

    async function addShippingReceiver(event, city) {

        event.preventDefault()

        const valueToBeSent = isJsonObject(city) ? JSON.parse(city) : city;

        const hasItemWithName = props.cityGrowth.shippingReceiverList.some(donorCity => donorCity.cityId === valueToBeSent.cityId);

        if (hasItemWithName) return;

        props.cityGrowth.shippingReceiverList.push(valueToBeSent)

        setDumie(!dumie)

    }

    function removeDonor(event, city, index) {
        event.preventDefault()
        props.cityGrowth.shippingDonorList.splice(index, 1)
        setDumie(!dumie)
    }

    function removeReceiver(event, city, index) {
        event.preventDefault()
        props.cityGrowth.shippingReceiverList.splice(index, 1)
        setDumie(!dumie)
    }

    useEffect(() => {

        if (props.cityGrowthPlans) setCityGrowthPlans(props.cityGrowthPlans)
        if (props.cityGrowth && props.cityGrowth.godBlessingId) {
            setSelectedGodsBlessingId(props.cityGrowth.godBlessingId)
        }
        if (props.cityGrowth) {
            if (props.cityGrowth.cityGrowthPlanName === "" && props.cityGrowthPlans && props.cityGrowthPlans.length > 0) {
                setSelectedCityGrowthPlanName(props.cityGrowthPlans[0].name)
            }
            if (props.cityGrowth.cityGrowthPlanName !== "") {
                setSelectedCityGrowthPlanName(props.cityGrowth.cityGrowthPlanName)
            }
            if (props.cityGrowth.cityGrowthList && props.cityGrowth.cityGrowthList.length > 0) {
                setSelectedShippingReceiverCity(props.cityGrowth.cityGrowthList[0])
                setSelectedShippingDonorCity(props.cityGrowth.cityGrowthList[0])
            }
        }

    }, [props.cityGrowth])

    return (
        <div>
            <Modal show={props.showModal} onHide={props.closeModal}>
                <div className="border-custom-orange border-solid border-2 p-4 rounded-lg">
                    <p>Selected user: {props.cityGrowth ? props.cityGrowth.accountName : ""}</p>
                    <Form>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="selectedPlanName">City plan name</InputGroup.Text>
                            <Form.Control id="newPlanName" aria-describedby="selectedPlanName"
                                          as="select"
                                          onChange={(event) => {
                                              console.log()
                                              setSelectedCityGrowthPlanName(event.target.value)
                                          }}
                                          value={selectedCityGrowthPlanName}
                                          disabled={!props.cityGrowth || !props.cityGrowth.shouldBuild}
                            >
                                {
                                    cityGrowthPlans ? cityGrowthPlans.map((cityPlan) => {
                                        return (<option key={cityPlan.name}
                                                        value={cityPlan.name}>{cityPlan.name}</option>)
                                    }) : null
                                }
                            </Form.Control>
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Checkbox
                                aria-label="shouldBuild"
                                checked={!!(props.cityGrowth && props.cityGrowth.shouldBuild)}
                                onChange={(event) => {
                                    props.cityGrowth.shouldBuild = event.target.checked
                                    setDumie(!dumie)
                                }}
                                disabled={!props.cityGrowth}
                            />
                            <Form.Control
                                aria-label="shouldBuild"
                                value={"Should build"}

                                disabled
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Checkbox
                                aria-label="shouldBoostProd"
                                checked={!!(props.cityGrowth && props.cityGrowth.shouldBoostProd)}
                                onChange={(event) => {
                                    props.cityGrowth.shouldBoostProd = event.target.checked
                                    setDumie(!dumie)
                                }}
                                disabled={!props.cityGrowth}
                            />
                            <Form.Control
                                aria-label="shouldBoostProd"
                                value={"Should boost prod"}

                                disabled
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Checkbox
                                aria-label="shouldDonateOnly"
                                checked={!!(props.cityGrowth && props.cityGrowth.shouldDonateOnly)}
                                onChange={(event) => {
                                    props.cityGrowth.shouldDonateOnly = event.target.checked
                                    setDumie(!dumie)
                                }}
                                disabled={!props.cityGrowth}
                            />
                            <Form.Control
                                aria-label="shouldDonateOnly"
                                value={"Should donate only"}

                                disabled
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Checkbox
                                aria-label="ShouldGoldOnly"
                                checked={!!(props.cityGrowth && props.cityGrowth.shouldGoldOnly)}
                                onChange={(event) => {
                                    props.cityGrowth.shouldGoldOnly = event.target.checked
                                    setDumie(!dumie)
                                }}
                                disabled={!props.cityGrowth}
                            />
                            <Form.Control
                                aria-label="ShouldGoldOnly"
                                value={"Should gold only"}

                                disabled
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Checkbox
                                aria-label="ShouldKeepFaith"
                                checked={!!(props.cityGrowth && props.cityGrowth.shouldKeepFaith)}
                                onChange={(event) => {
                                    props.cityGrowth.shouldKeepFaith = event.target.checked
                                    setDumie(!dumie)
                                }}
                                disabled={!props.cityGrowth}
                            />
                            <Form.Control
                                aria-label="ShouldKeepFaith"
                                value={"Should keep faith"}

                                disabled
                            />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Checkbox
                                aria-label="ShouldOvercharge"
                                checked={!!(props.cityGrowth && props.cityGrowth.shouldOvercharge)}
                                onChange={(event) => {
                                    props.cityGrowth.shouldOvercharge = event.target.checked
                                    setDumie(!dumie)
                                }}
                                disabled={!props.cityGrowth}
                            />
                            <Form.Control
                                aria-label="ShouldOvercharge"
                                value={"Should overcharge"}

                                disabled
                            />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Checkbox
                                aria-label="putSearchers"
                                checked={!!(props.cityGrowth && props.cityGrowth.putSearchers)}
                                onChange={(event) => {
                                    props.cityGrowth.putSearchers = event.target.checked
                                    setDumie(!dumie)
                                }}
                                disabled={!props.cityGrowth}
                            />
                            <Form.Control
                                aria-label="putSearchers"
                                value={"Put searchers in academies"}

                                disabled
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Checkbox
                                aria-label="doResearches"
                                checked={!!(props.cityGrowth && props.cityGrowth.doResearches)}
                                onChange={(event) => {
                                    props.cityGrowth.doResearches = event.target.checked
                                    setDumie(!dumie)
                                }}
                                disabled={!props.cityGrowth}
                            />
                            <Form.Control
                                aria-label="doResearches"
                                value={"Do researches"}

                                disabled
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Checkbox
                                aria-label="applyGodsBlessing"
                                checked={!!(props.cityGrowth && props.cityGrowth.applyGodsBlessing)}
                                onChange={(event) => {
                                    props.cityGrowth.applyGodsBlessing = event.target.checked
                                    setDumie(!dumie)
                                }}
                                disabled={!props.cityGrowth}
                            />
                            <Form.Control
                                aria-label="applyGodsBlessing"
                                value={"Apply gods blessing"}

                                disabled
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="godBlessingId">Gods blessing</InputGroup.Text>
                            <Form.Control
                                id="godBlessingId"
                                aria-describedby="godBlessingId"
                                as="select"
                                onChange={(event) => {
                                    setSelectedGodsBlessingId(event.target.value);
                                }}
                                value={selectedGodsBlessingId}
                                disabled={props.cityGrowth && !props.cityGrowth.applyGodsBlessing}
                            >
                                {Object.keys(godMapping).map(key => (
                                    <option key={key} value={key}>
                                        {godMapping[key]}
                                    </option>
                                ))}
                            </Form.Control>
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Checkbox
                                aria-label="shouldStartPirate16h"
                                checked={!!(props.cityGrowth && props.cityGrowth.shouldStartPirate16h)}
                                onChange={(event) => {
                                    props.cityGrowth.shouldStartPirate16h = event.target.checked
                                    setDumie(!dumie)
                                }}
                                disabled={!props.cityGrowth}
                            />
                            <Form.Control
                                aria-label="shouldStartPirate16h"
                                value={"Should start 16h pirate course"}

                                disabled
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Checkbox
                                aria-label="shouldShipResources"
                                checked={!!(props.cityGrowth && props.cityGrowth.shouldShipResources)}
                                onChange={(event) => {
                                    props.cityGrowth.shouldShipResources = event.target.checked
                                    setDumie(!dumie)
                                }}
                                disabled={!props.cityGrowth}
                            />
                            <Form.Control
                                aria-label="shouldShipResources"
                                value={"Should ship resources"}

                                disabled
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="selectedNewDonor">Shipping donor city</InputGroup.Text>
                            <Form.Control id="newDonorCity" aria-describedby="newDonorCity"
                                          as="select"
                                          onChange={(event) => {
                                              console.log(event.target.value)
                                              setSelectedShippingDonorCity(event.target.value)
                                          }}
                                          value={selectedShippingDonorCity}
                                          disabled={props.cityGrowth && !props.cityGrowth.shouldShipResources}>
                                {
                                    props.cityGrowth ? props.cityGrowth.cityList.map((city) => {
                                        return (<option key={city.cityId}
                                                        value={JSON.stringify(city)}>{city.cityName}</option>)
                                    }) : null
                                }
                            </Form.Control>
                            <button
                                className={"bg-custom-orange hover:bg-orange-700 text-white font-medium py-2 px-4 rounded-lg"}
                                onClick={(event) => addShippingDonor(event, selectedShippingDonorCity)}>Add
                            </button>
                        </InputGroup>
                        <span>Shipping donors list</span>
                        {props.cityGrowth && props.cityGrowth.shippingDonorList.length > 0 ?
                            <table className="min-w-max w-full table-auto rounded-t">
                                <thead>
                                <tr className="bg-custom-orange text-gray-600 uppercase text-sm leading-normal">
                                    City
                                </tr>
                                </thead>
                                <tbody>
                                {props.cityGrowth.shippingDonorList.map((city, index) => {
                                    return <tr>
                                        <td>{city.cityName} - {city.cityCoords}
                                            <button
                                                className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-red-700 rounded"
                                                onClick={(event) => removeDonor(event, city, index)}>Remove
                                            </button>
                                        </td>
                                    </tr>
                                })
                                }
                                </tbody>
                            </table> : null
                        }
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="selectedNewReceiver">Shipping receiver city</InputGroup.Text>
                            <Form.Control id="newReceiverCity" aria-describedby="newReceiverCity"
                                          as="select"
                                          onChange={(event) => {
                                              setSelectedShippingReceiverCity(event.target.value)
                                          }}
                                          value={selectedShippingReceiverCity}
                                          disabled={props.cityGrowth && !props.cityGrowth.shouldShipResources}
                            >
                                {
                                    props.cityGrowth ? props.cityGrowth.cityList.map((city) => {
                                        return (<option key={city.cityId}
                                                        value={JSON.stringify(city)}>{city.cityName}</option>)
                                    }) : null
                                }
                            </Form.Control>
                            <button
                                className={"bg-custom-orange hover:bg-orange-700 text-white font-medium py-2 px-4 rounded-lg"}
                                onClick={(event) => addShippingReceiver(event, selectedShippingDonorCity)}>Add
                            </button>
                        </InputGroup>
                        <span>Shipping receiver list</span>
                        {props.cityGrowth && props.cityGrowth.shippingReceiverList.length > 0 ?
                            <table className="min-w-max w-full table-auto rounded-t">
                                <thead>
                                <tr className="bg-custom-orange text-gray-600 uppercase text-sm leading-normal">
                                    City
                                </tr>
                                </thead>
                                <tbody>
                                {props.cityGrowth.shippingReceiverList.map((city, index) => {
                                    return <tr>
                                        <td>{city.cityName} - {city.cityCoords}
                                            <button
                                                className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-red-700 rounded"
                                                onClick={(event) => removeReceiver(event, city, index)}>Remove
                                            </button>
                                        </td>
                                    </tr>
                                })
                                }
                                </tbody>
                            </table> : null
                        }
                        {props.cityGrowth && props.cityGrowth.cityGrowthList.length > 0 ?
                            <table className="min-w-max w-full table-auto rounded-t">
                                <thead>
                                <tr className="bg-custom-orange text-gray-600 uppercase text-sm leading-normal">
                                    City infos
                                </tr>
                                <tr>City step</tr>
                                </thead>
                                <tbody>
                                {props.cityGrowth.cityGrowthList.map((city) => {
                                    return <tr>
                                        <td>{city.cityName} - {city.cityCoords} - {city.cityStep}</td>
                                    </tr>
                                })
                                }
                                </tbody>
                            </table> : null
                        }
                        <button onClick={(event) => {
                            event.preventDefault()
                            updateData()
                        }}
                                className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-green-700 rounded">Save
                        </button>
                        <button onClick={props.closeModal}
                                className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-red-700 rounded">Cancel
                        </button>
                    </Form>
                </div>
            </Modal>
        </div>
    )
}

export default CityGrowthConfiguration