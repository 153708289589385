/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import Layout from "../Layout";
import {useEffect, useState} from "react";
import {Bar, Doughnut} from "react-chartjs-2";
import {ArcElement, Chart as ChartJS, Tooltip} from 'chart.js';
import useAuth from "../auth/useAuth";
import {SERVER_URL} from "../Constants";
import PirateDashboard from "../component/dashboard/PirateDashboard";


ChartJS.register(ArcElement, Tooltip);

function Dashboard() {

    const [doughnutData, setDoughnutData] = useState();

    const [userData, setUserData] = useState();
    const {state} = useAuth();
    const [isLoading,setLoading] = useState();

    async function getData() {
        const jsonMission = {
            missionId: -1
        };
        const res = await fetch(SERVER_URL + "/api/mission/getmissions", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonMission)
        });
        const json = await res.json();
        let unUsed = state.licenseInfo.maxAccount - json.pirate - json.islandCheck - json.militaryCheck - json.cityTeleport - json.cityGrowth;
        let finalData = [json.pirate, json.islandCheck, json.militaryCheck, json.cityTeleport, json.cityGrowth, unUsed]
        setUserData(finalData);

        // Call computeChartData() and set the returned value as the chartData state
        setDoughnutData(computeChartData(finalData));
    }

    function computeChartData(finalData) {
        const data = {
            labels: ['Pirate', 'Island check', 'Military check', 'City teleport', 'City growth', 'Accounts unused'],
            datasets: [
                {
                    label: 'Accounts',
                    data: finalData,
                    backgroundColor: [
                        'rgba(0, 255, 0, 0.5)',
                        'rgba(54, 162, 235, 0.5)',
                        'rgba(255, 99, 132, 0.5)',
                        'rgba(125, 99, 132, 0.5)',
                        'rgba(0, 99, 132, 0.5)',
                        'rgba(255, 255, 132, 0.5)',
                    ],
                    borderColor: [
                        'rgba(0, 255, 0, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(125, 99, 132, 1)',
                        'rgba(0, 99, 132, 1)',
                        'rgba(255, 255, 132, 0.5)',
                    ],
                    borderWidth: 1,
                }],
            options: {
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: false
                }
            }
        };
        return data;
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <div>
            <Layout>
                <p>Accounts usage</p>
                <div className={"h-25"}>
                    {doughnutData ? <Doughnut  data={doughnutData}/> : null}

                </div>
                <PirateDashboard/>
            </Layout>
        </div>
    )
}

export default Dashboard;