import React, { useState } from 'react';
import 'tailwindcss/tailwind.css';
import './../App.css'
const TreeSection = ({ title, children, level }) => {
    return (
        <div
            className={`ml-${level * 4} mt-4 p-4 bg-gray-100 rounded shadow-md transform transition-opacity duration-500 ease-in-out fadeIn`}
            style={{ animationDelay: `${level * 0.5}s` }}
        >
            <h2 className="text-lg font-semibold">{title}</h2>
            {children}
        </div>
    );
};

const TestCityGrowth = () => {
    const [showSecondSection, setShowSecondSection] = useState(false);
    const [showThirdSection, setShowThirdSection] = useState(false);

    return (
        <div className="p-8">
            <TreeSection title="City Growth Plan Configuration" level={0}>
                {/* First section content */}
                <div>
                    <label>City plan name</label>
                    <input type="text" className="border p-2 rounded w-full" />
                </div>
                <button
                    onClick={() => setShowSecondSection(true)}
                    className="mt-4 bg-blue-500 text-white p-2 rounded"
                >
                    Next
                </button>
            </TreeSection>

            {showSecondSection && (
                <TreeSection title="City Growth Step Configuration" level={1}>
                    {/* Second section content */}
                    <div>
                        <label>Step index</label>
                        <input type="text" className="border p-2 rounded w-full" />
                    </div>
                    <button
                        onClick={() => setShowThirdSection(true)}
                        className="mt-4 bg-blue-500 text-white p-2 rounded"
                    >
                        Next
                    </button>
                </TreeSection>
            )}

            {showThirdSection && (
                <TreeSection title="City Growth Building Configuration" level={2}>
                    {/* Third section content */}
                    <div>
                        <label>Building</label>
                        <input type="text" className="border p-2 rounded w-full" />
                    </div>
                </TreeSection>
            )}
        </div>
    );
};

export default TestCityGrowth;
