/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import {Table} from "react-bootstrap";
import {useEffect, useState} from "react";
import IslandCheckConfiguration from "../component/islandcheck/IslandCheckConfiguration";
import '../index.css'
import Layout from "../Layout";
import {IconContext} from "react-icons";
import {MdNotStarted, MdOutlineLogout, MdPauseCircle} from "react-icons/md";
import {FiSettings} from "react-icons/fi";
import {SERVER_URL} from "../Constants";
import CustomToolTip from "../CustomToolTip";

function IslandCheck() {

    const [islandCheckData, setIslandCheckData] = useState([]);
    const [selectedIslandCheck, setSelectedIslandCheck] = useState();
    const [showConfig, setShowConfig] = useState(false)

    async function getData() {
        const jsonMission = {
            missionId: 3
        }
        const res = await fetch(SERVER_URL + "/api/mission/getmissions", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonMission)
        });
        const json = await res.json();
        if (json.error) {
            return;
        }
        setIslandCheckData(json)
        console.log(json)
    }


    useEffect(() => {
        getData()
    }, []);

    function getIslandCheckIndexFromList(accountId) {
        let index = 0;
        for (const element of islandCheckData) {
            console.log(element)
            if (element.accountId === accountId) {
                return index
            }
            index++;
        }
        return -1;
    }


    async function setMissionData(islandCheckObject) {
        const jsonMission = {
            accountId: islandCheckObject.accountId,
            action: !islandCheckObject.isEnabled,
            missionId: 3
        }
        const res = await fetch(SERVER_URL + "/api/mission/setmission", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonMission)
        });
        return await res.json();
    }

    async function logoutAccount(islandCheck) {

        const jsonObject = {
            accountId: islandCheck.accountId,
        }
        const res = await fetch(SERVER_URL + "/api/account/logoutaccount", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonObject)
        });
        let jsonRes = await res.json();
        console.log(jsonRes)
        getData()
    }

    function closeModal() {
        setShowConfig(false)
    }

    function openModal() {
        setShowConfig(true)
    }

    function handleOnClick(islandCheck) {

        let index = getIslandCheckIndexFromList(islandCheck.accountId)
        if (index === -1) return;
        const res = setMissionData(islandCheckData[index])
        if (res.error) {
            console.log("Error while updating")
            return;
        }
        const newIslandCheck = [...islandCheckData];
        newIslandCheck[index].isEnabled = !newIslandCheck[index].isEnabled;
        setIslandCheckData(newIslandCheck);

    }

    return (
        <div>
            <Layout>
                <IslandCheckConfiguration showModal={showConfig} islandCheck={selectedIslandCheck} openModal={openModal}
                                          closeModal={closeModal}/>
                <p>Island Check</p>
                <table className=" min-w-max w-full table-auto rounded-t">
                    <thead>
                    <tr className="bg-custom-orange text-gray-600 uppercase text-sm leading-normal">
                        <th className="py-3 text-center">Email</th>
                        <th className="py-3 text-center">Account name</th>
                        <th className="py-3 text-center">Server infos</th>
                        <th className="py-3 text-center">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {islandCheckData && islandCheckData.length > 0 ? islandCheckData.map((islandCheck) => {
                        return <tr>
                            {islandCheck.notifications.length > 0 ?
                                <td>
                                    <div className="p-1 flex items-center justify-center">
                                        <CustomToolTip text={islandCheck.notifications}/>
                                        {islandCheck.email}
                                    </div>
                                </td> : <td>
                                    {islandCheck.email}
                                </td>}
                            <td>{islandCheck.accountName}</td>
                            <td>{islandCheck.serverInfo}</td>
                            <td className="py-3 px-6 text-center whitespace-nowrap">
                                <div className="flex items-center justify-center">
                                    {islandCheck.isEnabled ?
                                        <IconContext.Provider value={{size: "26px", color: "green"}}>
                                            <MdPauseCircle onClick={() => handleOnClick(islandCheck)}
                                                           className={"m-1 hover:cursor-pointer"}/>
                                        </IconContext.Provider> : null}

                                    <IconContext.Provider value={{size: "26px", color: "red"}}>
                                        {islandCheck.isEnabled ? null :
                                            <MdNotStarted onClick={() => handleOnClick(islandCheck)}
                                                          className={"m-1 hover:cursor-pointer"}/>}
                                        <MdOutlineLogout onClick={() => logoutAccount(islandCheck)}
                                                         className={"m-1 hover:cursor-pointer"}/>
                                    </IconContext.Provider>
                                    <IconContext.Provider value={{size: "26px", color: "gray"}}>
                                        <FiSettings
                                            className={"m-1 hover:cursor-pointer"}
                                            onClick={() => {
                                                setSelectedIslandCheck(islandCheck)
                                                setShowConfig(!showConfig)
                                            }}
                                        />
                                    </IconContext.Provider>
                                </div>
                            </td>
                        </tr>
                    }) : null}
                    </tbody>
                </table>
            </Layout>
        </div>
    )
}

export default IslandCheck;