/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import React, {useEffect, useReducer, useState} from "react";
import AuthContext from "./AuthContext";
import {actions, initialState, reducer} from "./state";
import Cookies from 'js-cookie';
import {SERVER_URL} from "../Constants";

function AuthProvider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [cookie,setCookie] = useState(Cookies.get('token'));

    const init = async () => {

        let token,isAuth=false,licenseInfo,isExpired = false,isAdmin = false;
        try {
            if(cookie != null){
                token = cookie;
                const res = await fetch(SERVER_URL + "/api/getlicenseinfo", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: "{\"licenseKey\":\"" + token + "\"}"
                });
                const json = await res.json();

                if(json.error){
                    isAuth = false;
                    token = null;
                    licenseInfo = null;
                    Cookies.remove("token");
                }else{
                    licenseInfo=json;
                    isAuth = true;
                    isExpired = json.expired !== "false"
                }
            }
        } catch (err) {
            // handle error
        }
        dispatch({
            type: actions.init,
            data: {token, isAuth,licenseInfo,isExpired,isAdmin}
        });
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <AuthContext.Provider value={{
            state,
            dispatch
        }}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;