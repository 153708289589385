/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {Popover} from "react-bootstrap";
import {ImNotification} from "react-icons/im";
import {IconContext} from "react-icons";

function TriggerRendererProp(props) {

    const processTextNotificationLevel = (str) => {

        let defaultTextColorClass = "text-blue-500";

        switch (str.split("#")[0]) {
            case "WARN":
                defaultTextColorClass = "text-orange-500";
                break;
            case "ERROR":
                defaultTextColorClass = "text-red-500";
                break;
            case "SUCCESS":
                defaultTextColorClass = "text-green-500";
                break;
        }

        return (<p className={defaultTextColorClass}>{str.split("#")[1]}</p>)

    }

    const processIconNotificationLevel = () =>{

        let highestDefaultIconColor = "green";

        for (let i = 0; i < props.text.length; i++) {

            switch (props.text[i].split("#")[0]) {
                case "ERROR":
                    highestDefaultIconColor = "red";
                    return highestDefaultIconColor;
                case "WARN":
                    highestDefaultIconColor = "orange";
                    break;
                case "INFO":
                    if(highestDefaultIconColor !== "orange") highestDefaultIconColor = "blue";
                    break;
                default:
                    if(highestDefaultIconColor !== "orange" && highestDefaultIconColor !== "blue") highestDefaultIconColor = "green";
                    break;
            }
        }

        return highestDefaultIconColor

    }


    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Account notification</Popover.Header>
            <Popover.Body>
                {props.text ? props.text.map((str) => {
                    return (processTextNotificationLevel(str))
                }) : ""}
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <OverlayTrigger
                placement="right"
                overlay={popover}
            >
                {({ref, ...triggerHandler}) => (
                    <span ref={ref} {...triggerHandler}>
                        <IconContext.Provider value={{color: processIconNotificationLevel()}}>
                            <ImNotification className={"mr-2"}/>
                        </IconContext.Provider>
                    </span>
                )}
            </OverlayTrigger>
        </>
    );
}

export default TriggerRendererProp;