/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import {IconContext} from "react-icons";
import {GiIsland, GiPirateFlag, GiSpy,GiSailboat} from "react-icons/gi"
import {AiOutlinePlusCircle} from "react-icons/ai"
import {FaCity} from "react-icons/fa"
import {BsDiscord} from 'react-icons/bs'
import {MdOutlineScreenSearchDesktop} from "react-icons/md";
import {GoBrowser} from "react-icons/go";

function FeatureTiles() {

    return (

        <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
            <div className="py-12 md:py-20">

                {/* Items */}
                <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">

                    {/* 1st item */}
                    <div className="relative flex flex-col items-center border border-white border-solid border-1 p-6 rounded shadow-xl transform transition hover:scale-105 duration-300 ease-in-out">
                        <div className="h-16 w-16 bg-blue-600 rounded-full flex items-center justify-center">
                            <IconContext.Provider value={{size: "26px", color: "white"}}>
                                <BsDiscord/>
                            </IconContext.Provider>
                        </div>
                        <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 text-white">Discord integration</h4>
                        <p className="text-gray-600 text-center text-white">Add the discord bot to your server and receive notifications in real time.</p>

                    </div>

                    {/* 2nd item */}
                    <div className="relative flex flex-col items-center border border-white border-solid border-1 p-6 rounded shadow-xl transform transition hover:scale-105 duration-300 ease-in-out">
                        <div className="h-16 w-16 bg-blue-600 rounded-full flex items-center justify-center">
                            <IconContext.Provider value={{size: "26px", color: "white"}}>
                                <GiSpy/>
                            </IconContext.Provider>
                        </div>
                        <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 text-white">Proxy
                            sessions</h4>
                        <p className="text-gray-600 text-center text-white">All of your accounts will be using different ips to minimize risks.</p>
                    </div>

                    <div className="relative flex flex-col items-center border border-white border-solid border-1 p-6 rounded shadow-xl transform transition hover:scale-105 duration-300 ease-in-out">
                        <div className="h-16 w-16 bg-blue-600 rounded-full flex items-center justify-center">
                            <IconContext.Provider value={{size: "26px", color: "white"}}>
                                <GoBrowser/>
                            </IconContext.Provider>
                        </div>
                        <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 text-white">Web interface</h4>
                        <p className="text-gray-600 text-center text-white">Manage all your account from anywhere.</p>
                    </div>
                    {/* 3rd item */}
                    <div className="relative flex flex-col items-center border border-white border-solid border-1 p-6 rounded shadow-xl transform transition hover:scale-105 duration-300 ease-in-out">
                        <div className="h-16 w-16 bg-blue-600 rounded-full flex items-center justify-center">
                            <IconContext.Provider value={{size: "26px", color: "white"}}>
                                <GiIsland/>
                            </IconContext.Provider>
                        </div>
                        <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 text-white">Island check</h4>
                        <p className="text-gray-600 text-center text-white">Be aware of any spot changes made on an island and even better, take it !</p>
                    </div>

                    {/* 4th item */}
                    <div className="relative flex flex-col items-center border border-white border-solid border-1 p-6 rounded shadow-xl transform transition hover:scale-105 duration-300 ease-in-out">
                        <div className="h-16 w-16 bg-blue-600 rounded-full flex items-center justify-center">
                            <IconContext.Provider value={{size: "26px", color: "white"}}>
                                <AiOutlinePlusCircle/>
                            </IconContext.Provider>
                        </div>
                        <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 text-white">Account creation</h4>
                        <p className="text-gray-600 text-center text-white">Create loads of accounts quickly and easily.</p>
                    </div>

                    {/* 5th item */}
                    <div className="relative flex flex-col items-center border border-white border-solid border-1 p-6 rounded shadow-xl transform transition hover:scale-105 duration-300 ease-in-out">
                        <div className="h-16 w-16 bg-blue-600 rounded-full flex items-center justify-center">
                            <IconContext.Provider value={{size: "26px", color: "white"}}>
                                <GiPirateFlag/>
                            </IconContext.Provider>
                        </div>
                        <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 text-white">Pirate</h4>
                        <p className="text-gray-600 text-center text-white">Produce pirate points and make chains to pick them up easily !</p>
                    </div>

                    {/* 6th item */}
                    <div className="relative flex flex-col items-center border border-white border-solid border-1 p-6 rounded shadow-xl transform transition hover:scale-105 duration-300 ease-in-out">
                        <div className="h-16 w-16 bg-blue-600 rounded-full flex items-center justify-center">
                            <IconContext.Provider value={{size: "26px", color: "white"}}>
                                <FaCity/>
                            </IconContext.Provider>
                        </div>
                        <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 text-white">City growth</h4>
                        <p className="text-gray-600 text-center text-white">Automatically grow accounts following a plan that you can fully custom !</p>
                    </div>
                    <div className="relative flex flex-col items-center border border-white border-solid border-1 p-6 rounded shadow-xl transform transition hover:scale-105 duration-300 ease-in-out">
                        <div className="h-16 w-16 bg-blue-600 rounded-full flex items-center justify-center">
                            <IconContext.Provider value={{size: "26px", color: "white"}}>
                                <MdOutlineScreenSearchDesktop/>
                            </IconContext.Provider>
                        </div>
                        <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 text-white">Military check</h4>
                        <p className="text-gray-600 text-center text-white">Be aware of any attacks on your alliance using Discord with notifications !</p>
                    </div>
                    <div className="relative flex flex-col items-center border border-white border-solid border-1 p-6 rounded shadow-xl transform transition hover:scale-105 duration-300 ease-in-out">
                        <div className="h-16 w-16 bg-blue-600 rounded-full flex items-center justify-center">
                            <IconContext.Provider value={{size: "26px", color: "white"}}>
                                <GiSailboat/>
                            </IconContext.Provider>
                        </div>
                        <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 text-white">Resource shipping</h4>
                        <p className="text-gray-600 text-center text-white">Configure accounts to send resources to specific cities !</p>
                    </div>
                </div>

            </div>
        </div>

    )
}

export default FeatureTiles;