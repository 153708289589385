/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import Layout from "../Layout";
import {PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js";

function Payment(){


    return(
        <div>
            <Layout>
                <p>Payment</p>
                <PayPalScriptProvider options={{ "client-id": "AWMDLckO1Lj0tmCVso5EonFpGK9KO7OYQj8pueEojN6tfHy_RIRjGKrd07GgsQVn4j-DhPWHWceYcleW" ,"currency":"EUR"}}>
                    <PayPalButtons
                        createOrder={(data, actions) => {
                            return actions.order.create({
                                purchase_units: [
                                    {
                                        amount: {
                                            value: "1.99",
                                        },
                                    },
                                ],
                            });
                        }}
                        onApprove={(data, actions) => {
                            return actions.order.capture().then((details) => {
                                const name = details.payer.name.given_name;
                                alert(`Transaction completed by ${name}`);
                            });
                        }}
                    />
                </PayPalScriptProvider>

            </Layout>
        </div>
    )
}

export default Payment;