import pirateFortress from './assets/buildings/pirate_fortress.png';
import palace from './assets/buildings/palace.png';
import tradingPort from './assets/buildings/trading_port.png';
import tradingPost from './assets/buildings/trading_post.png';
import governorResidence from './assets/buildings/governor_residence.png';
import architectOffice from './assets/buildings/architect_office.png';
import academy from './assets/buildings/academy.png';
import alchemistTower from './assets/buildings/alchemist_tower.png';
import barracks from './assets/buildings/barracks.png';
import blackMarket from './assets/buildings/black_market.png';
import carpenter from './assets/buildings/carpenter.png';
import depot from './assets/buildings/depot.png';
import dockyard from './assets/buildings/dockyard.png';
import embassy from './assets/buildings/embassy.png';
import fireworkTestArea from './assets/buildings/firework_test_area.png';
import foresterHouse from './assets/buildings/forester_house.png';
import glassblower from './assets/buildings/glassblower.png';
import godsShrine from './assets/buildings/gods_shrine.png';
import hideout from './assets/buildings/hideout.png';
import museum from './assets/buildings/museum.png';
import optician from './assets/buildings/optician.png';
import seaChartArchive from './assets/buildings/sea chart archive.png';
import shipyard from './assets/buildings/shipyard.png';
import stonemason from './assets/buildings/stonemason.png';
import tavern from './assets/buildings/tavern.png';
import temple from './assets/buildings/temple.png';
import townHall from './assets/buildings/town_hall.png';
import townWall from './assets/buildings/town_wall.png';
import warehouse from './assets/buildings/warehouse.png';
import winePress from './assets/buildings/wine_press.png';
import winery from './assets/buildings/winery.png';
import workshop from './assets/buildings/workshop.png';

const Images = {
    dockyard,
    embassy,
    fireworkTestArea,
    foresterHouse,
    glassblower,
    godsShrine,
    hideout,
    museum,
    optician,
    seaChartArchive,
    shipyard,
    stonemason,
    tavern,
    temple,
    townHall,
    townWall,
    warehouse,
    winePress,
    winery,
    workshop,
    pirateFortress,
    palace,
    tradingPort,
    tradingPost,
    governorResidence,
    architectOffice,
    academy,
    alchemistTower,
    barracks,
    blackMarket,
    carpenter,
    depot

};

export default Images;