/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import {BsDiscord} from 'react-icons/bs'
import {FaChevronCircleRight} from 'react-icons/fa'
import FeaturesTiles from "../../component/FeaturesTiles";
import {Link} from "react-router-dom";
import Header from "./Header";

function Pricing() {
    return (
        <div className="outerDiv leading-normal tracking-normal text-indigo-400 bg-cover bg-fixed">
            <div className="h-full p-6">

                <Header/>


                <div className="container pt-24 md:pt-36 mx-auto flex flex-wrap flex-col md:flex-row items-center">

                    <div className="flex flex-col w-full xl:w-2/5 justify-center lg:items-start overflow-y-hidden">
                        <h1 className="my-4 text-3xl md:text-5xl text-white opacity-75 font-bold leading-tight text-center md:text-left">
                            The best
                            <span
                                className="bg-clip-text text-transparent bg-gradient-to-r from-green-400 via-pink-500 to-purple-500 ml-2 mr-2">
                                Ikariam automated tool
                            </span>
                            to save time !
                        </h1>
                        <p className="leading-normal text-base md:text-2xl mb-8 text-center md:text-left w-full">
                            Play the game on a whole other level
                        </p>

                        <form className="bg-gray-900 opacity-75 w-full  rounded-lg px-8 pt-6 pb-8 mb-4 text-center">
                            <div className="mb-4">
                                <span>You're looking for more informations, you have any question or you need support ? </span>
                            </div>

                            <div className="flex items-center justify-center pt-2">
                                <button
                                    className="w-full inline-block bg-discord text-white font-bold py-2 px-4 rounded focus:ring transform transition hover:scale-105 duration-300 ease-in-out"
                                >
                                    <BsDiscord className="inline-block"/>
                                    <span className="ml-2 inline-block">Join the discord</span>
                                </button>
                            </div>
                        </form>
                    </div>


                    <div className="w-full xl:w-3/5 p-12 overflow-hidden">
                        <iframe width="700" height="394" src="https://www.youtube.com/embed/Uno2lylUdF8"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>
                    </div>

                </div>
            </div>
            <div className="h-1 w-50 pt-5 max-w-6xl mx-auto px-4 sm:px-6 border-b border-gray-500"></div>
            <FeaturesTiles/>
            <div
                className="leading-normal text-base md:text-2xl mb-8 text-center md:text-left w-full">
                <button className="m-auto transition duration-300 ease-in-out hover:border-gray-400 border-2 border-transparent rounded p-3 transform transition hover:scale-110 duration-300 ease-in-out">
                    <span className="inline-block"><Link className="hover:text-white" to="/pricing">Interested ? Take a look at our pricing <FaChevronCircleRight className="inline-block"/></Link></span>
                </button>
            </div>
            <div className="w-full pt-16 pb-6 text-sm text-center md:text-left fade-in">
                <span className="text-gray-500 no-underline hover:no-underline">&copy; Oracle 2023</span>
            </div>
        </div>
    )
}

export default Pricing