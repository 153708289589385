/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import '../../App.css';
import {useEffect, useState} from "react";
import {Form, InputGroup} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {SERVER_URL} from "../../Constants";
import {IconContext} from "react-icons";
import {MdNotStarted, MdOutlineLogout, MdPauseCircle} from "react-icons/md";

function KeyGenerator() {

    useEffect(() => {
        getMissionData();
    }, [])

    const [value, setValue] = useState({
        "canPirate": false,
        "canRaiding": false,
        "canIslandCheck": false,
        "canCreateAccount": false,
        "canCityTeleport": false,
        "canMilitaryCheck": false,
        "canIslandCheckMines": false,
        "canCityGrowth": false,
        "maxAccount": 0,
        "days": 3,
        "discordId": 0,
        "email": ""
    })

    const [missionData, setMissionData] = useState([])

    function updateChange(event, target) {
        setValue(prevState => {
            // create a copy of the value object
            const updatedValue = {...prevState};
            // update the target property
            updatedValue[target] = event.target.checked;
            // return the updated value object
            return updatedValue;
        });
    }

    function updateAccountNumberChange(event, target) {
        setValue(prevState => {
            // create a copy of the value object
            const updatedValue = {...prevState};
            // update the target property
            updatedValue[target] = event.target.value;
            // return the updated value object
            return updatedValue;
        });
    }

    async function genKey(event) {
        event.preventDefault()
        console.log(value);

        const res = await fetch(SERVER_URL + "/api/admin/generatekey", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(value)
        });

        const jsonRes = await res.json();
        if (jsonRes.error) {

        }
        console.log(jsonRes);

    }

    async function getMissionData() {

        const res = await fetch(SERVER_URL + "/api/admin/getallmissions", {
            method: 'GET',
            credentials: 'include',
        });

        const jsonRes = await res.json();
        if (jsonRes.error) {

        } else {
            setMissionData(jsonRes);
        }

    }

    async function logoutAccount(mission) {

        const jsonObject = {
            accountId: mission.accountId,
        }
        const res = await fetch(SERVER_URL + "/api/admin/logoutaccount", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonObject)
        });
        let jsonRes = await res.json();
        if (jsonRes.error) {

        } else {

            getMissionData();
        }
    }

    async function getThreadInfos() {

        const res = await fetch(SERVER_URL + "/api/admin/getAllThreads", {
            method: 'GET',
            credentials: 'include',
        });
        const jsonRes = await res.json();
        if (jsonRes.error) {

        } else {
            console.log(jsonRes)
        }
    }

    async function updateMissionData(missionObject) {
        const jsonMission = {
            accountId: missionObject.accountId,
            action: !missionObject.isEnabled,
            missionId: missionObject.missionId
        }
        const res = await fetch(SERVER_URL + "/api/admin/setmission", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonMission)
        });
        const jsonRes = await res.json();
        if (jsonRes.error) {

        } else {

        }
    }

    return (
        <div>

            <p>Key generation </p>
            <button onClick={getThreadInfos}>Do not press
            </button>
            <Form>
                <Form.Group>
                    <Form.Check onChange={(event) => updateChange(event, "canPirate")} type="switch" id="switch1"
                                label="Pirate"/>
                    <Form.Check onChange={(event) => updateChange(event, "canRaiding")} type="switch" id="switch2"
                                label="Raiding"/>
                    <Form.Check onChange={(event) => updateChange(event, "canIslandCheck")} type="switch"
                                id="switch3" label="Island Check"/>
                    <Form.Check onChange={(event) => updateChange(event, "canCreateAccount")} type="switch"
                                id="switch4" label="Create account"/>
                    <Form.Check onChange={(event) => updateChange(event, "canCityTeleport")} type="switch"
                                id="switch5" label="City teleport"/>
                    <Form.Check onChange={(event) => updateChange(event, "canMilitaryCheck")} type="switch"
                                id="switch6" label="Military Check"/>
                    <Form.Check onChange={(event) => updateChange(event, "canIslandCheckMines")} type="switch"
                                id="switch7" label="Island Check Mines"/>
                    <Form.Check onChange={(event) => updateChange(event, "canCityGrowth")} type="switch"
                                id="switch7" label="City Growth"/>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon3">
                            Max account
                        </InputGroup.Text>
                        <Form.Control onChange={(event) => updateAccountNumberChange(event, "maxAccount")}
                                      type="number" id="maxAccount" value={value.maxAccount}/>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon3">
                            Discord ID
                        </InputGroup.Text>
                        <Form.Control onChange={(event) => updateAccountNumberChange(event, "discordId")}
                                      type="number" id="discordId" value={value.discordId}/>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon3">
                            Email
                        </InputGroup.Text>
                        <Form.Control onChange={(event) => updateAccountNumberChange(event, "email")}
                                      type="text" id="email" value={value.email}/>
                    </InputGroup>
                </Form.Group>
                <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="radioOptions"
                        id="radioOption1"
                        value="3"
                        onClick={(event) => updateAccountNumberChange(event, "days")}
                        defaultChecked
                    />
                    <label className="form-check-label" htmlFor="radioOption1">
                        3 days
                    </label>
                </div>

                <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="radioOptions"
                        id="radioOption2"
                        value="15"
                        onClick={(event) => updateAccountNumberChange(event, "days")}
                    />
                    <label className="form-check-label" htmlFor="radioOption2">
                        15 days
                    </label>
                </div>

                <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="radioOptions"
                        id="radioOption3"
                        value="30"
                        onClick={(event) => updateAccountNumberChange(event, "days")}
                    />
                    <label className="form-check-label" htmlFor="radioOption3">
                        30 days
                    </label>
                </div>
                <br/>
                <button
                    onClick={event => genKey(event)}
                    className="bg-custom-orange hover:bg-orange-700 text-white font-medium py-2 px-4 rounded-lg">
                    Generate Key
                </button>
            </Form>

            <table className="min-w-max w-full table-auto rounded-t">
                <thead>
                <tr className="bg-custom-orange text-gray-600 uppercase text-sm leading-normal">
                    <th className="py-3 text-center">License key</th>
                    <th className="py-3  text-center">Account infos</th>
                    <th className="py-3  text-center">Mission</th>
                    <th className="py-3  text-center">Actions</th>

                </tr>
                </thead>
                <tbody className="text-gray-600 text-sm font-light">
                {missionData.map((mission) => {
                    return <tr className="border-b border-gray-200 hover:bg-gray-100">

                        <td className="py-3 px-6 text-center whitespace-nowrap">{mission.key}</td>
                        <td className="py-3 px-6 text-center whitespace-nowrap">{mission.accountName} ({mission.accountGroup})</td>
                        <td className="py-3 px-6 text-center whitespace-nowrap">{mission.missionId}</td>

                        <td className="py-3 px-6 text-center whitespace-nowrap">
                            <div className="flex items-center justify-center">
                                {mission.isEnabled ?
                                    <IconContext.Provider value={{size: "26px", color: "green"}}>
                                        <MdPauseCircle onClick={() => updateMissionData(mission)}
                                                       className={"m-1 hover:cursor-pointer"}/>
                                    </IconContext.Provider> : null}

                                <IconContext.Provider value={{size: "26px", color: "red"}}>
                                    {mission.isEnabled ? null :
                                        <MdNotStarted onClick={() => updateMissionData(mission)}
                                                      className={"m-1 hover:cursor-pointer"}/>}
                                    <MdOutlineLogout onClick={() => logoutAccount(mission)}
                                                     className={"m-1 hover:cursor-pointer"}/>
                                </IconContext.Provider>
                            </div>
                        </td>
                    </tr>
                })}
                </tbody>
            </table>

        </div>
    )
}

export default KeyGenerator;
