/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */


import {Button, Form, Table} from "react-bootstrap";
import {useEffect, useState} from "react";
import {SERVER_URL} from "../../Constants";


function IslandCheckConfiguration(props) {

    const [islandCoords,setIslandCoords] = useState("");
    const [position,setPosition] = useState(-1);
    const [selectedCityId,setSelectedCityId] = useState(-1);

    async function removeTeleport(cityId){

        if(!props.cityTeleport) return;

        const jsonMission = {
            accountId:props.cityTeleport.accountId,
            cityId: cityId,
        }

        const res = await fetch(SERVER_URL + "/api/cityteleport/removecityteleport", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonMission)
        });

        const jsonRes =  await res.json();
        if(jsonRes.error){

        }
        console.log(jsonRes);

    }

    async function addTeleport(){

        if(!props.cityTeleport) return;

        const jsonMission = {
            accountId:props.cityTeleport.accountId,
            islandCoords:islandCoords,
            position:position,
            cityId:selectedCityId
        }

        console.log(jsonMission)

        const res = await fetch(SERVER_URL + "/api/cityteleport/addcityteleport", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonMission)
        });

        const jsonRes =  await res.json();
        if(jsonRes.error){

        }
        console.log(jsonRes);

    }

    useEffect(() => {
        // perform some action based on the updated props
        if(props.cityTeleport && props.cityTeleport.cityList.length > 0)
            setSelectedCityId(props.cityTeleport.cityList[0].cityId)
        console.log("update props")
    }, [props.cityTeleport])

    return (
        <div className={"flex items-center justify-center "}>
            <div className={"border-custom-orange border-solid border-2  p-4 rounded-lg top-50"}
                 style={{position: "absolute", zIndex: 100}}>
                <h1 className="font-bold text-xl">City teleport configuration</h1>
            <p>selected city teleport: {props.cityTeleport == null ? "none" : props.cityTeleport.accountName}</p>
            <Form>
                <Form.Control as="select" onChange={(event) => setSelectedCityId(event.target.value)} value={selectedCityId}>
                    {
                        props.cityTeleport ? props.cityTeleport.cityList.map((loadedCity) => {
                            return (<option key={loadedCity.cityId} value={loadedCity.cityId}>{props.cityTeleport.accountName} - {loadedCity.cityName}</option>)
                        }) : null
                    }
                </Form.Control>


                <Form.Group controlId="formCityList">
                    <Form.Label>Island coordinates</Form.Label>
                    <Form.Control onChange={(event) => setIslandCoords(event.target.value)} type="text" placeholder="Enter coords xx:yy" />
                </Form.Group>

                <Form.Group controlId="formCityList">
                    <Form.Label>Spot position</Form.Label>
                    <Form.Control onChange={(event) => setPosition(event.target.value)} type="text" placeholder="Position" />
                </Form.Group>


                <Button variant="primary" type="button" onClick={addTeleport} >
                    Add teleport
                </Button>

            </Form>
            <p>Island lists</p>
            <Table style={{margin: '10px'}} striped bordered hover>
                <thead>
                <tr>
                    <th>City</th>
                    <th>Target Island</th>
                    <th>Target Position</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {props.cityTeleport ? props.cityTeleport.cityTeleportList.map((cityTp) => {
                    return <tr>
                        <td>{cityTp.sourceCityName}</td>
                        <td>{cityTp.targetIslandCoords}</td>
                        <td>{cityTp.targetPosition}</td>
                        <td><Button onClick={() => removeTeleport(cityTp.sourceCityId)} variant={"danger"}>Remove</Button></td>
                    </tr>
                }) : null}
                </tbody>
            </Table>
        </div>
        </div>
    )
}

export default IslandCheckConfiguration;