/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import {Button, Form, InputGroup, Modal, Table} from "react-bootstrap";
import {useEffect, useState} from "react";
import {SERVER_URL} from "../../Constants";
import {notifyFailed, notifySuccess} from "../../ToastHelper";


function IslandCheckConfiguration(props) {

    const [islandCoords, setIslandCoords] = useState("");
    const [loadedCities, setLoadedCities] = useState([]);
    const [selectedCityId, setSelectedCityId] = useState(-1);
    const [shouldCheckEntireIsland, setShouldCheckEntireIsland] = useState(true)
    const [dumie, setDumie] = useState(false)

    async function loadIsland(event) {
        event.preventDefault()
        if (!props.islandCheck) return;

        const jsonMission = {
            accountId: props.islandCheck.accountId,
            islandCoords: islandCoords,
        }
        const res = await fetch(SERVER_URL + "/api/islandcheck/getisland", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonMission)
        });

        const jsonRes = await res.json();
        if (jsonRes.error) {

        } else {
            setLoadedCities(jsonRes);
            if (jsonRes.length != 0) setSelectedCityId(jsonRes[0].id)
        }
        console.log(jsonRes);
    }

    async function addIsland(event) {

        event.preventDefault()

        let newSpot = [islandCoords, shouldCheckEntireIsland ? -1 : loadedCities.findIndex(a => a.id == selectedCityId)]

        props.islandCheck.islandCheckList.push(newSpot)

        setDumie(!dumie)

    }


    async function removeIsland(islandSpot) {
        console.log(islandSpot)

        props.islandCheck.islandCheckList.splice(props.islandCheck.islandCheckList.indexOf(islandSpot), 1)

        setDumie(!dumie)
    }

    async function updateData() {
        if (!props.islandCheck) return;
        let jsonEdit = {
            shouldAutoColonize: props.islandCheck.shouldAutoColonize,
            accountId: props.islandCheck.accountId,
            islandCheckList: props.islandCheck.islandCheckList
        }

        console.log(jsonEdit)

        const res = await fetch(SERVER_URL + "/api/account/setaccount", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonEdit)
        });
        const json = await res.json();
        if (json.error) {
            notifyFailed("An error occured: " + json.error)
        } else {
            notifySuccess("Changes saved")
            props.closeModal()
        }
        console.log(json)

    }

    useEffect(() => {
        // perform some action based on the updated props
        console.log("update props")
    }, [])

    return (
        <div className={""}>
            <Modal show={props.showModal} onHide={props.closeModal}>
                <div className={"border-custom-orange border-solid border-2  p-4 rounded-lg top-50"}>
                    <h1 className="font-bold text-xl">Island check configuration</h1>
                    <p>{props.islandCheck == null ? "none" : props.islandCheck.accountName}</p>
                    <Form>
                        <InputGroup className="mb-3">
                            <InputGroup.Checkbox
                                aria-label="shouldAutoColonize"
                                checked={props.islandCheck ? props.islandCheck.shouldAutoColonize : false}
                                onChange={(event) => {
                                    setDumie(!dumie)
                                    props.islandCheck.shouldAutoColonize = event.target.checked
                                }}
                            />
                            <Form.Control
                                aria-label="shouldAutoColonize"
                                value={"Should auto colonize"}
                                disabled
                            />
                        </InputGroup>
                        <Form.Group controlId="formIslandCoords">
                            <Form.Label>Island coordinates</Form.Label>
                            <Form.Control onChange={(event) => setIslandCoords(event.target.value)} type="text"
                                          placeholder="Enter coords xx:yy"/>
                        </Form.Group>


                        <button
                            onClick={loadIsland}
                            className={"bg-custom-orange hover:bg-orange-700 text-white font-medium py-2 px-4 rounded-lg py-2"}>
                            Load island
                        </button>

                        <Form.Group>
                            <Form.Check
                                type="radio"
                                label="Specific Position"
                                name="position"
                                id="position-1"
                                value="specific"
                                checked={!shouldCheckEntireIsland}
                                onChange={() => setShouldCheckEntireIsland(false)}
                            />
                            <Form.Check
                                type="radio"
                                label="Entire Island"
                                name="position"
                                id="position-2"
                                value="entire"
                                checked={shouldCheckEntireIsland}
                                onChange={() => setShouldCheckEntireIsland(true)}
                            />
                        </Form.Group>

                        <Form.Control as="select" disabled={shouldCheckEntireIsland}
                                      onChange={(event) => setSelectedCityId(event.target.value)}
                                      value={selectedCityId}>
                            {
                                loadedCities.map((loadedCity) => {
                                    return (<option key={loadedCity.id}
                                                    value={loadedCity.id}>{loadedCity.ownerName} - {loadedCity.name}</option>)
                                })
                            }
                        </Form.Control>
                        <button
                            onClick={addIsland}
                            className={"bg-custom-orange hover:bg-orange-700 text-white font-medium py-2 px-4 rounded-lg py-2"}>
                            Add island
                        </button>
                    </Form>

                    <p>Island lists</p>
                    <Table style={{margin: '10px'}} striped bordered hover>
                        <thead>
                        <tr>
                            <th>Coords</th>
                            <th>Position</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {props.islandCheck ? props.islandCheck.islandCheckList.map((islandSpot) => {
                            return <tr>
                                <td>{islandSpot[0]}</td>
                                <td>{islandSpot[1]}</td>
                                <td><Button onClick={() => removeIsland(islandSpot)} variant={"danger"}>Remove</Button>
                                </td>
                            </tr>
                        }) : null}
                        </tbody>
                    </Table>
                    <button onClick={() => {
                        updateData()
                    }}  className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-green-700 rounded">Save</button>
                    <button onClick={props.closeModal} className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-red-700 rounded">Cancel</button>
                </div>

            </Modal>
        </div>
    )
}

export default IslandCheckConfiguration;