/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import Layout from "../../Layout";
import {useEffect, useState} from "react";
import KeyGenerator from "./KeyGenerator";
import LicenseManagement from "./LicenseManagement";


function AdminPanel() {


    const [showKeyGen, setShowKeyGen] = useState(true)

    useEffect(() => {

    }, [])

    return (
        <div>
            <Layout>
                <ul
                    className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
                    role="tablist"
                >
                    <li className="-mb-px mr-2 last:mr-0 flex-auto text-center hover:cursor-pointer">
                        <a
                            className={
                                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                                (showKeyGen === true
                                    ? "text-white bg-custom-orange"
                                    : "text-custom-orange bg-white")
                            }
                            onClick={e => {
                                e.preventDefault();
                                setShowKeyGen(true);
                            }}
                        >
                            Key generator
                        </a>
                    </li>
                    <li className="-mb-px mr-2 last:mr-0 flex-auto text-center hover:cursor-pointer">
                        <a
                            className={
                                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                                (showKeyGen === false
                                    ? "text-white bg-custom-orange"
                                    : "text-custom-orange bg-white")
                            }
                            onClick={e => {
                                e.preventDefault();
                                setShowKeyGen(false);
                            }}
                        >
                            Key management
                        </a>
                    </li>
                </ul>
                {
                    showKeyGen ? <KeyGenerator/> : <LicenseManagement/>
                }
            </Layout>
        </div>
    )
}

export default AdminPanel;